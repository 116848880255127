import React, { useCallback, useEffect, useState } from "react";
import {
  NumberInput,
  Select,
  TwoButtons,
  DateInput,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  executeCalculator,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import { table } from "../../../assets/images";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step2 = ({ handleNext, handleBack }) => {
  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes.find((x) => x.name === value);
  };

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );
  const [extendedFamily, setExtendedFamily] = useState([
    {
      dob: null,
    },
  ]);
  useEffect(() => {
    if (extendedFamilies) {
      setExtendedFamily(extendedFamilies);
    }
  }, []);
  


  const checklength = useCallback(() => {
    const spouseLength = customAttributes.spouse_cover === 0 ? 1 : 0;
    const childLength =
      customAttributes.numberOfChildren === undefined
        ? 0
        : customAttributes.numberOfChildren;
    return spouseLength + childLength + extendedFamily.length;
  }, [customAttributes]);


  const getConstraints = () => {
    var val = {};
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
      val = {
        ...val,
        "Spouse Cover": {
          presence: {
            allowEmpty: false,
          },
        },
        "Child Cover": {
          presence: {
            allowEmpty: false,
          },
        },
        [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
      if (customAttributes?.child_cover === 0) {
        val = {
          ...val,
          "Number of Children": {
            presence: {
              allowEmpty: false,
              message: "^Number of children can't be blank",
            },
            numericality: {
              onlyInteger: true,
              greaterThan: 0,
              lessThanOrEqualTo: 6,
            },
          },
        };
      }
      if (getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES") {
        val = {
          ...val,
          [getAttributes("Extended Family Cover Amount")?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      }
      if (getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES") {
        extendedFamily?.map((ex, i) => {
          val = {
            ...val,
            [`Family Member ${i + 1} date of birth`]: {
              presence: {
                allowEmpty: false,
                message: "^ Date of birth can’t be blank",
              },
            },
          };
        });
      }
    } else {
      if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
        return {};
      } else {
        val = {
          ...val,
          "Spouse Cover": {
            presence: {
              allowEmpty: false,
            },
          },
          "Child Cover": {
            presence: {
              allowEmpty: false,
            },
          },
          [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
        if (customAttributes.child_cover === 0) {
          val = {
            ...val,
            "Number of Children": {
              presence: {
                allowEmpty: false,
                message: "^Number of children can't be blank",
              },
              numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThanOrEqualTo: 6,
              },
            },
          };
        }
        if (getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES") {
          val = {
            ...val,
            [getAttributes("Extended Family Cover Amount")?.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
        if (getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES") {
          extendedFamily?.map((ex, i) => {
            val = {
              ...val,
              [`Family Member ${i + 1} date of birth`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Date of birth can’t be blank",
                },
              },
            };
          });
        }
      }
    }
    return val;
  };

  const reduxState = useSelector((state) => state);
  const state = useSelector((state) => state?.products?.riskAttributes);

  const productOptions = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const premiumAmountCalc = () => {
    return calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total;
  };
  
  useEffect(() => {
    dispatch(
      updateCustomAttributes({
        type: "PREMIUM",
        payload: premiumAmountCalc(),
      })
    );
  }, []);

  const handleChangeCustom = (val, name, code) => {
    let obj = { ...customAttributes, [name]: val };

    dispatch(
      updateCustomAttributes({
        type: "CUSTOM_ATTRIBUTES",
        payload: obj,
      })
    );
  };

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  const calculateMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 85);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 0);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const handleDelete = (index) => {
    var arr = [...extendedFamily];
    arr.splice(index, 1);
    setExtendedFamily(arr);
    dispatch(
      updateCustomAttributes({
        type: "EXTENDED_FAMILIES",
        payload: arr,
      })
    );
  };

  const bandsState = useSelector((state) => state?.products?.bands);

  const [bands, setBands] = useState([
    {
      name: "Aged from 1-15",
      value: null,
    },
    {
      name: "Aged from 16-25",
      value: null,
    },
    {
      name: "Aged from 26-35",
      value: null,
    },
    {
      name: "Aged from 36-45",
      value: null,
    },
    {
      name: "Aged from 46-55",
      value: null,
    },
    {
      name: "Aged from 56-65",
      value: null,
    },
    {
      name: "Aged from 66-75",
      value: null,
    },
    {
      name: "Aged from 76-85",
      value: null,
    },
  ]);

  const over60Option = () => {
    if (dateOfBirth && calculateAge60(dateOfBirth) >= 60) {
      return getAttributes("Cover Option 60 Plus")?.value === "Individual";
    } else {
      return false;
    }
  };

  const totalParents = () => {
    var total = riskAttributes?.find((x) => x.name === "Cover Option")?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
      ? 4
      : 0;
  };

  const checkOverOption = () => {
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
      return true;
    } else {
      if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
        return false;
      } else {
        return true;
      }
    }
  };

  const getValuations = (name) => {
    return productOptions?.valuations?.values
      .find((x) => x.name === name)
      ?.value?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updateBand = (year) => {
    const currentBands = bands;
    switch (true) {
      case year > 0 && year <= 15:
        currentBands[0] = {
          name: "Aged from 1-15",
          value: currentBands[0].value ? currentBands[0].value + 1 : 1,
        };
        break;
      case year >= 16 && year <= 25:
        currentBands[1] = {
          name: "Aged from 16-25",
          value: currentBands[1].value ? currentBands[1].value + 1 : 1,
        };
        break;
      case year >= 26 && year <= 35:
        currentBands[2] = {
          name: "Aged from 26-35",
          value: currentBands[2].value ? currentBands[2].value + 1 : 1,
        };
        break;
      case year >= 36 && year <= 45:
        currentBands[3] = {
          name: "Aged from 36-45",
          value: currentBands[3].value ? currentBands[3].value + 1 : 1,
        };
        break;
      case year >= 46 && year <= 55:
        currentBands[4] = {
          name: "Aged from 46-55",
          value: currentBands[4].value ? currentBands[4].value + 1 : 1,
        };
        break;
      case year >= 56 && year <= 65:
        currentBands[5] = {
          name: "Aged from 56-65",
          value: currentBands[5].value ? currentBands[5].value + 1 : 1,
        };
        break;
      case year >= 66 && year <= 75:
        currentBands[6] = {
          name: "Aged from 66-75",
          value: currentBands[6].value ? currentBands[6].value + 1 : 1,
        };
        break;
      case year >= 76 && year <= 85:
        currentBands[7] = {
          name: "Aged from 76-85",
          value: currentBands[7].value ? currentBands[7].value + 1 : 1,
        };
        break;

      default:
        break;
    }
    var arr = [...currentBands];
    setBands(arr);
    dispatch(
      updateCustomAttributes({
        type: "BANDS",
        payload: arr,
      })
    );
  };

  const calculateage = (date) => {
    const splitt = date.split("-");
    var years = Number(splitt[0]);
    var d = new Date();
    let currentYear = d.getFullYear();

    if (splitt[1] <= 10) {
      years -= 1;
    }
    updateBand(currentYear - years);
  };

  const handleSubmit = async () => {
    var values = {};
    values = {
      ...values,
      "Spouse Cover": customAttributes?.spouse_cover,
      "Child Cover": customAttributes?.child_cover,
      [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: getAttributes(
        ATTRIBUTES.EXTENDED_FAMILY
      )?.value,
      "Number of Children": customAttributes?.numberOfChildren,
      [getAttributes("Extended Family Cover Amount")?.name]: getAttributes(
        "Extended Family Cover Amount"
      )?.value,
      "Number of Extended Family": bands
        ?.filter((y) => y.value)
        ?.reduce((a, b) => a + b.value, 0),
    };

    extendedFamily?.map((ex, inde) => {
      values = {
        ...values,
        [`Family Member ${inde + 1} date of birth`]: ex.dob,
      };
    });

    const errors = validate(values, getConstraints());
    if (errors || errors !== undefined) {
      var errorArray = Object.keys(errors);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      return setErrors(errors);
    }
    customAttributes?.extendedCover === 0 &&
      extendedFamily.forEach((x) => {
        calculateage(x.dob);
      });

    setIsLoading(true);
    try {
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Number of Children"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.name === "Extended Family Aged 1-15 Years"
                          ? bands?.find((x) => x.name === "Aged from 1-15")
                              ?.value
                          : y.name === "Extended Family Aged 16-25 Years"
                          ? bands?.find((x) => x.name === "Aged from 16-25")
                              ?.value
                          : y.name === "Extended Family Aged 26-35 Years"
                          ? bands?.find((x) => x.name === "Aged from 26-35")
                              ?.value
                          : y.name === "Extended Family Aged 36-45 Years"
                          ? bands?.find((x) => x.name === "Aged from 36-45")
                              ?.value
                          : y.name === "Extended Family Aged 46-55 Years"
                          ? bands?.find((x) => x.name === "Aged from 46-55")
                              ?.value
                          : y.name === "Extended Family Aged 56-65 Years"
                          ? bands?.find((x) => x.name === "Aged from 56-65")
                              ?.value
                          : y.name === "Extended Family Aged 66-75 Years"
                          ? bands?.find((x) => x.name === "Aged from 66-75")
                              ?.value
                          : y.name === "Extended Family Aged 76-85 Years"
                          ? bands?.find((x) => x.name === "Aged from 76-85")
                              ?.value
                          : y.name === "Date of Birth"
                          ? dateOfBirth
                          : y.value ?? null,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
        })
      );
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("bw_care_insurance_indicative_quote_next_cta", {
        Spouse: customAttributes?.spouse_cover === 0 ? "Yes" : "No",
        Child: customAttributes?.child_cover === 0 ? "Yes" : "No",
        Extended: customAttributes?.extendedCover === 0 ? "Yes" : "No",
        NumberOfChildren: customAttributes?.numberOfChildren ?? 0,
        NumberofExtendedFamily: bands?.filter((y) => y.value)?.reduce((a, b) => a + b.value, 0) ?? 0,
      });
      handleNext();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

 const handleBackTrack = () => {
   //trackUserEvents
   trackUserEvents("bw_care_insurance_indicative_quote_back_cta", {});
   handleBack();
 }
  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">Indicative quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Indicative quote</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className={classNames(style.step_2_info, "mt-5 mb-4")}>
          <h4 className="text-white f-700">
            {dateOfBirth && calculateAge60(dateOfBirth) < 60
              ? "Mosako Under 60 Plan"
              : "Mosako Over 60 Plan"}
          </h4>
          <h6 className="secondary-color f-500">
            {getAttributes("Cover Option")?.value}
          </h6>
          <div className={style.step_2_info_container}>
            <h6 className="secondary-color f-700">Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Monthly cost</h6>
              <h6 className="primary-color f-700">
                P
                {premiumAmountCalc()
                  ? premiumAmountCalc()
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
                  : 0}
              </h6>
            </div>
            <div style={{ height: "30px" }} />
            <h6 className="secondary-color f-700">Level of cover</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Main member</h6>
              <h6 className="primary-color f-700">
                P{getValuations("Main member")}
              </h6>
            </div>

            {checkOverOption() ? (
              <>
                {riskAttributes?.[0]?.value === 0 ? null : (
                  <>
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Spouse</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Spouse")}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Child 16-21</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Child 16-21")}
                      </h6>
                    </div>
                    {getValuations("Child 6-15") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 6-15</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 6-15")}
                        </h6>
                      </div>
                    )}
                    {getValuations("Child 9-15") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 9-15</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 9-15")}
                        </h6>
                      </div>
                    )}
                    {moment().diff(dateOfBirth?.replaceAll("/", "-"), "years") >
                      60 && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 6-8</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 6-8")}
                        </h6>
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Child 0-5</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Child 0-5")}
                      </h6>
                    </div>
                    {moment().diff(dateOfBirth?.replaceAll("/", "-"), "years") <
                      60 && totalParents() >= 1 ? (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">
                          Parent/Parent-in-law
                        </h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Parent/Parent inlaw")}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                    {getAttributes("Extended Family")?.value === "YES" &&
                      getValuations("Extended Family") && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">
                            Extended Family
                          </h6>
                          <h6 className="primary-color f-700">
                            P{getValuations("Extended Family")}
                          </h6>
                        </div>
                      )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!over60Option() && (
          <>
            <TwoButtons
              id="Spouse Cover"
              half={true}
              title={"Add a spouse (Included in package)?"}
              errors={errors?.["Spouse Cover"]}
              state={customAttributes?.spouse_cover}
              onClick={(value) => {
                if (value === 1) {
                  var obj = {
                    ...customAttributes,
                    spouse_cover: value,
                  };
                  return dispatch(
                    updateCustomAttributes({
                      type: "CUSTOM_ATTRIBUTES",
                      payload: obj,
                    })
                  );
                }
                handleChangeCustom(value, "spouse_cover", "SPOUSE_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
            <div style={{ height: "30px" }} />
            <TwoButtons
              id="Child Cover"
              half={true}
              title={"Add Children (6 Included in package)?"}
              errors={errors?.["Child Cover"]}
              state={customAttributes?.child_cover}
              onClick={(value) => {
                if (value === 1) {
                  var obj = {
                    ...customAttributes,
                    numberOfChildren: 0,
                    child_cover: value,
                  };
                  return dispatch(
                    updateCustomAttributes({
                      type: "CUSTOM_ATTRIBUTES",
                      payload: obj,
                    })
                  );
                }
                handleChangeCustom(value, "child_cover", "CHILD_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
            {customAttributes?.child_cover === 0 && (
              <div
                className={classNames(style.twoButtonContainer, "p-3")}
                id={"Number of Children"}
              >
                <p className="primary-color f-700">
                  {"How many children do you want to cover?"}
                </p>
                <NumberInput
                  half={true}
                  type="number"
                  hideLabel={true}
                  customWidth={200}
                  id={"Number of Children"}
                  value={customAttributes?.numberOfChildren}
                  errors={errors?.["Number of Children"]}
                  onChange={(value) => {
                    if (parseInt(value?.target?.value) < 0) return;
                    handleChangeCustom(
                      isNaN(parseInt(value.target.value))
                        ? ""
                        : parseInt(value.target.value),
                      "numberOfChildren",
                      "CHILD_COVER"
                    );
                  }}
                  label={"How many children do you want to cover?"}
                />
              </div>
            )}
            <div style={{ height: "20px" }} />
            <TwoButtons
              id="Extended Family"
              half={true}
              title={"Do you want to cover any extended family?"}
              errors={errors?.["Extended Family"]}
              state={customAttributes?.extendedCover}
              onClick={(value) => {
                if (value === 1) {
                  var obj = {
                    ...customAttributes,
                    numberOfExtended: 0,
                    extendedCover: value,
                  };
                  var arr = [...riskAttributes];
                  const index = riskAttributes?.findIndex(
                    (x) => x.name === "Extended Family"
                  );
                  arr[index] = {
                    ...arr[index],
                    value: value === 0 ? "YES" : "NO",
                  };
                  dispatch(updatedAttributes(arr, "QUQ"));
                  return dispatch(
                    updateCustomAttributes({
                      type: "CUSTOM_ATTRIBUTES",
                      payload: obj,
                    })
                  );
                }
                var obj = {
                  ...customAttributes,
                  extendedCover: value,
                };
                var arr = [...riskAttributes];
                const index = riskAttributes?.findIndex(
                  (x) => x.name === "Extended Family"
                );
                arr[index] = {
                  ...arr[index],
                  value: value === 0 ? "YES" : "NO",
                };
                dispatch(updatedAttributes(arr, "QUQ"));
                dispatch(
                  updateCustomAttributes({
                    type: "CUSTOM_ATTRIBUTES",
                    payload: obj,
                  })
                );
                handleChangeCustom(value, "extendedCover", "EXTENDED_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />

            {customAttributes?.extendedCover === 0 && (
              <div style={{ maxWidth: "460px", width: "100%" }}>
                <div className={style.coverOptions}>
                  <p className="primary-color f-700">
                    Choose the cover option amount
                  </p>
                  <div
                    className={`d-flex justify-content-between align-items-center ${
                      width > 770 ? "flex-row" : "flex-column"
                    }`}
                  >
                    <div>
                      <Select
                        half={true}
                        hideLabel={true}
                        id="Extended Family Cover Amount"
                        width={200}
                        defaultValue={
                          getAttributes("Extended Family Cover Amount")
                            ?.value ?? "Select cover amount"
                        }
                        options={JSON.parse(
                          getAttributes("Extended Family Cover Amount")
                            ?.settings
                        )?.items.map((x) => ({ name: x, value: x }))}
                        onClick={(value) => {
                          var arr = [...riskAttributes];
                          const index = riskAttributes?.findIndex(
                            (x) => x.name === "Extended Family Cover Amount"
                          );
                          arr[index] = { ...arr[index], value: value };
                          dispatch(updatedAttributes(arr, "QUQ"));
                        }}
                      />
                      {errors?.["Extended Family Cover Amount"] ? (
                        <span className="errors">
                          {errors?.["Extended Family Cover Amount"]?.[0]}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      <button
                        id="premium"
                        style={{
                          width: "200px",
                          borderRadius: 10,
                          marginTop: width > 700 ? "0px" : "20px",
                          backgroundColor: "#eaecf7",
                        }}
                        data-tip
                        data-for="registerTip"
                        onMouseOver={() => {
                          console.log("In");
                        }}
                        onMouseLeave={() => {
                          console.log("Out");
                        }}
                      >
                        Premium per extended family member
                      </button>
                      <ReactTooltip
                        id="registerTip"
                        place="bottom"
                        effect="solid"
                        data-background-color="transparent"
                      >
                        <img
                          src={table}
                          style={
                            width > 770
                              ? { height: "300px", width: "auto" }
                              : { height: "200px", width: "auto" }
                          }
                        />
                      </ReactTooltip>
                    </div>
                  </div>
                  {/* <p className="primary-color f-700 mt-3">
                    How many extended family do you want to cover in each age
                    group below?
                  </p> */}
                  {/* <div className="d-flex justify-content-between align-items-center">
                    <p className="primary-color mb-1 f-700">Family members</p>
                    <p className="primary-color mb-1 f-700">Age groups bands</p>
                  </div> */}
                  <div style={{ height: 20 }} />
                  <Divider />
                  <div style={{ height: 20 }} />
                  {/* {bands.map((band, index) => (
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <NumberInput
                        half={true}
                        hideLabel={true}
                        type="number"
                        defaultValue={band?.value}
                        customWidth={width > 770 ? 200 : 150}
                        value={band?.value}
                        onChange={(value) => {
                          if (parseInt(value?.target?.value) < 0) return;
                          var arr = [...bands];
                          arr[index] = {
                            ...arr[index],
                            value:
                              parseInt(value.target.value) === NaN
                                ? 0
                                : parseInt(value.target.value),
                          };
                          setBands(arr);
                          dispatch(
                            updateCustomAttributes({
                              type: "BANDS",
                              payload: arr,
                            })
                          );
                        }}
                      />
                      <p className="primary-color mb-1 f-700">
                        {band?.name ?? ""}
                      </p>
                    </div>
                  ))}
                  {errors?.["Number of Extended Family"] ? (
                    <span className="errors">
                      {errors?.["Number of Extended Family"]?.[0]}
                    </span>
                  ) : (
                    <></>
                  )} */}
                  {extendedFamily?.map((item, index) => (
                    <div>
                      <p className="primary-color f-700">
                        Family member #{index + 1}
                      </p>
                      <DateInput
                        label="Date of birth of family member *"
                        value={item?.dob}
                        minDate={calculateMinDate(item?.cover)}
                        maxDate={calculateMaxDate(item?.cover)}
                        errors={
                          errors?.[`Family Member ${index + 1} date of birth`]
                        }
                        onChange={(e) => {
                          var arr = [...extendedFamily];
                          arr[index] = { ...arr[index], dob: e.target.value };
                          setExtendedFamily(arr);
                          dispatch(
                            updateCustomAttributes({
                              type: "EXTENDED_FAMILIES",
                              payload: arr,
                            })
                          );
                        }}
                      />

                    
                      <div className="d-flex justify-content-between flex-row mt-3 mb-3">
                        {index >= 1 && (
                          <button
                            id="delete_member"
                            style={{
                              backgroundColor: "#E33252",
                              borderRadius: 5,
                              color: "white",
                              width: 100,
                              height: 40,
                            }}
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </button>
                        )}
                        {extendedFamily?.length - 1 === index && (
                          <button
                            id="add_member"
                            style={{
                              backgroundColor: "#2C317E",
                              borderRadius: 5,
                              color: "white",
                              width: 180,
                              height: 40,
                            }}
                            onClick={() => {
                              if (extendedFamily?.length > 7) return;
                              setExtendedFamily([
                                ...extendedFamily,
                                { dob: null },
                              ]);
                              dispatch(
                                updateCustomAttributes({
                                  type: "EXTENDED_FAMILIES",
                                  payload: [...extendedFamily, { dob: null }],
                                })
                              );
                            }}
                          >
                            Add family member
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        <div style={{ height: "20px" }} />
      </div>
      <div style={{ height: "20px" }} />
      <NavigationButtons
        back={handleBackTrack}
        loading={isLoading}
        isBackDisabled={isLoading}
        isNextDisabled={
          premiumAmountCalc() === 0 || premiumAmountCalc() === 0.0
        }
        backId={"step2_back"}
        nextId={"step2_next"}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step2;
