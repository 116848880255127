/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";
import { SocialIcons } from "../../components";
import {
  phoneIcon,
  mailIcon,
  mapIcon,
  facebookIcon,
  instagramIcon,
  twitterIcon,
  linkedin,
} from "../../assets/images/index.js";
import classNames from "classnames";
import axios from "axios";
import CONSTANTS from "../../utils/constants";
// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { validate } from "validate.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Contact({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);
  const [contact, setContact] = useState({});
    const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.products.riskAttributes);
  const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);


  useEffect(async() => {
    setIsLoading(true);
     await dispatch(login());
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      setIsLoading(false);
  },[])

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

   const handleChange = (e) => {
     const name = e.target.name;
     const value = e.target.value;

     setContact({ ...contact, [name]: value });
   };

  const submitContact = async (e) => {
    e.preventDefault();

    const errors = validate(contact, contactSchema);

    if (errors) {
      setErrors(errors);
      return;
    }
    try {
      setLoading(true);
      // const res = await axios.post(
      await axios.post(
        `${CONSTANTS.MIDDLEWARE_URL}/contact`,
        {
          ...contact,
          productName: "Letshego-Botswana",
          subject: `Query received - ${CONSTANTS.SITE_URL}`,
          broker_email_address: companyDetails.contactEMail.length
            ? companyDetails.contactEMail
            : "Botswana.Communications@letshego.com",
        },
        {}
      );
      setContact({});
      setErrors({});
      setLoading(false);
      toast.success(
        "Thank you for contacting us. Our team will reach out to you soon."
      );
      // console.log(res);
    } catch (err) {
      setLoading(false);
      toast.success("Something wrong. Please try again later.");
    }
  };


  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  const socials = [
    {
      link: "/",
      label: companyDetails?.contactNumber,
      icon: phoneIcon,
      disabled: false,
      type: "phone",
    },
    {
      link: companyDetails?.contactEMail?.length
        ? `mailto:${companyDetails.contactEMail}`
        : "",
      label: companyDetails?.contactEMail,
      icon: mailIcon,
      type: "mail",
    },
    {
      link: companyDetails?.address,
      // label: companyDetails?.address,
      label: `${companyDetails?.address ? companyDetails?.address : " "} ${
        companyDetails?.address2 ? companyDetails?.address2 : " "
      } ${companyDetails?.address3 ? companyDetails?.address3 : " "} ${
        companyDetails?.city ? companyDetails?.city : " "
      }  ${companyDetails?.province ? companyDetails?.province : " "}  ${
        companyDetails?.country ? companyDetails?.country : " "
      }  ${companyDetails?.postalCode ? companyDetails?.postalCode : " "}`,
      icon: mapIcon,
      disabled: true,
      type: "text",
    },
    {
      link: companyDetails?.facebook,
      label: "Facebook",
      icon: facebookIcon,
      type: "text",
    },
    {
      link: companyDetails?.twitter,
      label: "Twitter",
      icon: twitterIcon,
      type: "text",
    },
    {
      link: companyDetails?.instagram,
      label: "Instagram",
      icon: instagramIcon,
      type: "text",
    },
    {
      link: companyDetails?.linkedIn,
      // link:`${companyDetails?.linkedIn ? companyDetails?.linkedIn : "444"}`,
      label: "LinkedIn",
      icon: linkedin,
      type: "text",
    },
  ];

  const [isRecaptchaComplete, setIsRecaptachaComplete] = useState(false);


  return !isLoading ? (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        {/* <Row className="mt-5"> */}
        <Row>
          <div
            style={{
              marginTop: "25px",
              textAlign: "center",
              color: "#fbcf2c",
              fontSize: "32px",
              fontWeight: "700",
            }}
          >
            Get in touch{" "}
          </div>
          <div
            style={{
              textAlign: "center",
              color: "#2f2e80",
              fontSize: "32px",
              fontWeight: "700",
            }}
          >
            and let's get your every click covered.
          </div>
        </Row>
        <Row className="mt-5 container boxBorder">
          <Col
            lg={12}
            xs={12}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <form
              onSubmit={submitContact}
              noValidate
              className={width > 770 ? "w-50" : "w-100"}
            >
              <div className="form-group contact_form_input">
                <label>Name</label>
                <input
                  name="name"
                  value={contact?.name}
                  placeholder=""
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.name}</span>
              </div>
              <div className="form-group contact_form_input mt-4">
                <label>Last name</label>
                <input
                  name="lastName"
                  value={contact?.lastName}
                  placeholder=""
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.lastName}</span>
              </div>

              <div className="form-group contact_form_input mt-4">
                <label>Email</label>
                <input
                  name="email"
                  value={contact?.email}
                  placeholder=""
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.email}</span>
              </div>

              <div className="form-group contact_form_input mt-4">
                <label>Message</label>
                <textarea
                  name="message"
                  rows={5}
                  value={contact?.message}
                  placeholder=""
                  style={{ height: "100px" }}
                  onChange={(e) => handleChange(e)}
                  className={classNames("w-100 mt-0 form-control ")}
                />
                <span className="errors">{errors.message}</span>
              </div>
              {/* <div className="mt-3">
                <ReCAPTCHA
                  sitekey={CONSTANTS.RECAPTCHA_KEY}
                  onChange={() => setIsRecaptachaComplete(true)}
                />
              </div> */}
              {/* {isRecaptchaComplete && ( */}
              <button
                disabled={loading}
                className="contact_submit"
                name="contact-submit-button"
                id="contact-submit-button"
                aria-label="contact-submit-button"
              >
                {" "}
                {loading ? "Sending..." : "Send"}
              </button>
              {/* )} */}
            </form>
            <Row className="mt-5 pt-5 justify-content-center">
              <Col
                lg={10}
                xs={12}
                className={`d-flex justify-content-around align-items-center ${
                  width > 430 ? "flex-row" : "flex-column"
                }`}
              >
                {Object.keys(companyDetails || {}).length > 0 ? (
                  <Row>
                    {socials
                      .filter((fil) => fil.link && fil.link.length !== 0)
                      .map((ex) => (
                        <Col lg={4} xs={6} className="mt-3">
                          <SocialIcons
                            link={ex.link}
                            type={ex.type}
                            disabled={ex.disabled}
                            label={ex.label}
                            icon={ex.icon}
                          />
                        </Col>
                      ))}
                  </Row>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={6} xs={12}></Col>
        </Row>
      </Container>
      <div style={{ height: "100px" }}></div>
    </div>
  ) : (
    <>
      <Loader />
      <div style={{ height: "150px" }}></div>
    </>
  );
}

export default Contact;

const contactSchema = {
  name: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: "[a-zA-Z].+",
      flags: "g",
      message: "can only contain alphabets",
    },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
  message: {
    presence: { allowEmpty: false },
  },
};
