import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  Dropdown,
  Info,
  NumberInput,
  CountryInput,
  Select,
  TextInput,
  TwoButtons,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";
import {
  CreatePolicy,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import moment from "moment";
const Step2 = ({ handleNext, handleBack }) => {
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoadiing] = useState(false);
  const premiumAmount = 0;

  const handleSubmit = () => {
    //trackUserEvents
    trackUserEvents("bw_mosako_quote_next_cta", {});
    handleNext();
  };

  
 const handleBackTrack = () => {
   //trackUserEvents
   trackUserEvents("'bw_mosako_quote_back_cta", {});
   handleBack();
 };
  const premiumAmountCalc = () => {
    return 0;
  };

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes.find((x) => x.name === value);
  };

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const productOptions = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const getParsed = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const totalParents = () => {
    var total = QUQRiskAttributes?.find(
      (x) => x.name === "Cover Option"
    )?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
      ? 4
      : 0;
  };

  const checkOverOption = () => {
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
      return true;
    } else {
      if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
        return false;
      } else {
        return true;
      }
    }
  };

  const getValuations = (name) => {
    return productOptions?.valuations?.values
      .find((x) => x.name === name)
      ?.value?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">Quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Quote</h2>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <div className={classNames(style.step_2_info, "mt-5 mb-4")}>
          <h4 className="text-white f-700">
            {dateOfBirth && calculateAge60(dateOfBirth) < 60
              ? "Mosako Under 60 Plan"
              : "Mosako Over 60 Plan"}
          </h4>
          <h6 className="secondary-color f-500">
            {getAttributes("Cover Option")?.value}
          </h6>
          <div className={style.step_2_info_container}>
            <h6 className="secondary-color f-700">Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Monthly cost</h6>
              <h6 className="primary-color f-700">
                P
                {calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                  ?.total
                  ? calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
                      ?.toFixed(2)
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
                  : 0}
              </h6>
            </div>
            <div style={{ height: "30px" }} />
            <h6 className="secondary-color f-700">Level of cover</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Main member</h6>
              <h6 className="primary-color f-700">
                P{getValuations("Main member")}
              </h6>
            </div>
            {checkOverOption() && (
              <>
                {riskAttributes?.[0]?.value === 0 ? null : (
                  <>
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Spouse</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Spouse")}
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Child 16-21</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Child 16-21")}
                      </h6>
                    </div>
                    {getValuations("Child 6-15") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 6-15</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 6-15")}
                        </h6>
                      </div>
                    )}
                    {getValuations("Child 9-15") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 9-15</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 9-15")}
                        </h6>
                      </div>
                    )}
                    {getValuations("Child 6-8") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 6-8</h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Child 6-8")}
                        </h6>
                      </div>
                    )}

                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Child 0-5</h6>
                      <h6 className="primary-color f-700">
                        P{getValuations("Child 0-5")}
                      </h6>
                    </div>
                    {dateOfBirth &&
                    calculateAge60(dateOfBirth) < 60 &&
                    totalParents() >= 1 ? (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">
                          Parent/Parent-in-law
                        </h6>
                        <h6 className="primary-color f-700">
                          P{getValuations("Parent/Parent inlaw")}
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}

                    {getAttributes("Extended Family")?.value === "YES" &&
                      getValuations("Extended Family") && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">
                            Extended Family
                          </h6>
                          <h6 className="primary-color f-700">
                            P{getValuations("Extended Family")}
                          </h6>
                        </div>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">
            P
            {calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ??
              0 ??
              0.0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={() => {
          if (dateOfBirth && calculateAge60(dateOfBirth) > 60) {
            if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
              trackUserEvents("'bw_mosako_quote_back_cta", {});
              return handleBack(0);
            } else {
              handleBackTrack();
            }
          } else {
            handleBackTrack();
          }
        }}
        isBackDisabled={isLoading}
        backId={"step3_back"}
        nextId={"step3_next"}
        loading={isLoading}
        next={() => {
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
      message: "^ Invalid mobile number",
    },
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
    },
  },
  eMail: {
    presence: {
      allowEmpty: false,
    },
    email: true,
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
    },
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step2;
