/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import brochure from "../../assets/documents/Mosako Funeral Cover Flyer.pdf";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Products({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            Products{" "}
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            1. Mosako Funeral Plan
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Mosako Funeral Plan is primarily for individuals who do not get paid
            their salaries through the banks, that is, the unbanked. It caters
            primarily for those in jobs such as domestic workers, bus
            conductors, herd boys, gardeners, church organizations, industrial
            workers, individuals in the informal sector, self-employed persons
            etc. The policyholder can cover up to a maximum of 27 family members
            in a single policy. The main member, spouse and children that are 16
            years and older may be covered for P15,000. The main member has the
            option to add a life cover for up to P235 000 and this life cover is
            available for the main member only.
          </p>
        </Row>
        <Row>
          <div style={{ color: "#2f2e80" }}>
            <span
              style={{
                color: "#2f2e80",
              }}
            >
              The premium payments can be made through:
            </span>
            <ul>
              <li>Orange Money</li>
              <li>Mascom Myzaka</li>
              <li>BTC Smega Kazang</li>
              <li>Any Botswana Post Office</li>
            </ul>
          </div>
        </Row>
        <Row>
          <div style={{ color: "#2f2e80" }}>
            {" "}
            Mosako Funeral Cover Flyer:{"   "}&nbsp;
            <a
              style={{ color: "#2f2e80", fontWeight: "bold" }}
              rel="noreferrer"
              href={brochure}
              target="_blank"
            >
              [ Download Cover ]
            </a>
          </div>
        </Row>
        <div style={{ height: "200px" }}></div>
      </Container>
    </div>
  );
}

export default Products;
