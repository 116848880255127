/* eslint-disable */

// <----- API URL  -------> 
export const APIUrl = {
    login: '/lg/authorisation/login',
};

// <----- Environment types  -------> 
export const ENV_TYPE = {
    DEV: 'dev',
    PROD: 'prod',
    UAT: 'uat'
};

// <----- Environments  -------> 
export const ENV = {
    ['dev']: 'https://briisk-i-dev-api.azure-api.net',
    ['uat']: 'https://briisk-i-uat-api.azure-api.net',
    ['prod']: 'https://briisk-i-prod-api.azure-api.net',
};


export const OCP_APIM_SUBSCRIPTION_KEY = {
    ['dev']: 'ec2659d3859d421d801776e4efad5780',
    ['uat']: '3296a582dbb540b8ae4843332c638a3e',
    ['prod']: 'd6cd16e3f619492bbc3f75fccf0aba6c'
}
  
export const PRODUCT_INSTANCE_ID = {
    ['dev']: '54b0d998-3e6f-5a37-9879-9949adb05de0',
    ['uat']: '765102d5-46e4-4ad1-943f-10da18bcb6fc', // Same as for DEV
    ['prod']: '78f2f4a2-4f79-4cc8-84e6-af0fc6044970'
}



export const BANK_BRANCH_GUID = {
    ['dev']: 'F5D22457-30FC-8FC4-90DB-BC43DC258464',
    ['uat']: '201413A3-4410-B6C1-498E-7FD5D7100874',
    ['prod']: '7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01'
}

export const MIDDLEWARE_URL = {
    ['dev']: 'https://react-middleware-dev.azurewebsites.net',
    // ['dev']: 'http://localhost:5000',
    ['uat']: 'https://react-middleware-uat.azurewebsites.net',
    ['prod']: 'https://react-middleware-prod.azurewebsites.net'
    // ['prod']: 'http://localhost:8000',
}

/* eslint-disable */


export const SITE_URL = {
  ["dev"]: "https://react-letshego-botswana-dev.azurewebsites.net/",
  ["prod"]: "https://letsgoinsure-bw.briisk.io/",
};