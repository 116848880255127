import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  Info,
  NumberInput,
  CountryInput,
  TextInput,
  TwoButtons,
  FileUpload,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  CreatePolicy,
  policySaleAttachments,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import moment from "moment";
import axios from "axios";
import localforage from "localforage";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step4 = ({ handleNext, handleBack, handleFileUpload, files }) => {
  const auth = useSelector((state) => state?.auth?.refererData?.customer);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [memberHooks, setMemberHooks] = useState({ ...mainMember });
  const [authHooks, setAuthHooks] = useState({ ...auth });
  const [inputs, setInputs] = useState({
    mobilePhoneCode: "+267",
  });
  const dispatch = useDispatch();

  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const extendedFamilyState = useSelector(
    (state) => state.products?.extendedAttributes
  );
  const attOpts = useSelector(
    (state) => state.products?.policyAttachmentsOptions
  );

  const [childAttributes, setChildAttributes] = useState(childState);

  const [parentAttributes, setParentAttributes] = useState(parentState);

  const [extendedFamilyAttributes, setextendedFamilytAttributes] =
    useState(extendedFamilyState);

  const [beneCount, setBeneCount] = useState(1);

  const riskAttributes = useSelector((state) =>
    state.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  useEffect(() => {
    var dateOfBirth = customAttributes.dateOfBirth;
    if (Object.keys(mainMember).length >= 3) {
      setInputs({
        ...mainMember,
        dob: inputs?.dob ?? dateOfBirth,
        mobilePhoneCode:
          mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode ?? "+267",
      });
    } else {
      setInputs({
        mobilePhoneCode:
          mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode ?? "+267",
        dob:
          inputs?.dob ??
          dateOfBirth ??
          moment(authHooks?.birthDate).format("DD-MM-YYYY"),
        firstName: authHooks?.firstName,
        lastName: authHooks?.lastName,
        eMail: authHooks?.eMail,
        mobilePhone: authHooks?.mobilePhone,
        address: authHooks?.address,
        address2: authHooks?.address2,
      });
    }
  }, []);

  const totalParents = () => {
    var total = riskAttributes
      ?.find((z) => z.code === RISKS.QUQ)
      ?.attributes?.find((x) => x.name === "Cover Option")?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
      ? 4
      : 0;
  };

  const handleChange = (evt, instanceId, code) => {
    var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, code));
  };

  const handleChangePolicy = (e, name) => {
    let obj = { ...inputs };
    if (name) {
      obj = {
        ...inputs,
        [name]: `${e}`,
      };
    } else {
      obj = {
        ...inputs,
        [e.target.name]: e.target.value,
      };
    }
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
  };

  const handleCustomChange = (evt, name, index) => {
    var arr = [...childAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setChildAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "CHILD_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomParentChange = (evt, name, index) => {
    var arr = [...parentAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setParentAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "PARENT_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomExtendedChange = (evt, name, index) => {
    var arr = [...extendedFamilyAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setextendedFamilytAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "EXTENDED_FAMILY_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleSpouseChange = (e) => {
    let obj = { ...customAttribues, spouseCountryCode: e };
    dispatch(
      updateCustomAttributes({
        type: "CUSTOM_ATTRIBUTES",
        payload: obj,
      })
    );
  };
  const calculateMinDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - value ?? 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      date.setFullYear(date.getFullYear() - 24);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMinDate = (type) => {
    const date = new Date();

    // date.setFullYear(date.getFullYear() - 36);
     date.setFullYear(date.getFullYear() - 123);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: new Date(),
      postalAddress: "",
      gender: "",
      country: "",
      telephoneNumber: "",
      idNumber: "",
      "Telephone Number Code": "+267",
      "Phone Number Code": "+267",
    },
  ]);

  const calculateBeneMaxDate = () => {
    const date = new Date();
    return new Date();
  };

  const calculateMaxDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      // Spouse -> undefined
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - value ?? 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [errors, setErrors] = useState({});
  const renderComponents = useCallback(
    (item, custom, index, item2) => {
      switch (item.dataType) {
        case 1:
          return (
            <TextInput
              id={item.name}
              label={item.description}
              errors={
                custom
                  ? errors[`${item.name} ${index + 1}`] ??
                    errors[`${item.name}`]
                  : errors[item.name]
              }
              name={item.name}
              maxLength={30}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                  ? parentState?.[index]
                    ? parentState?.[index]?.[item?.name]
                    : null
                  : custom === "extended"
                  ? extendedFamilyState?.[index]
                    ? extendedFamilyState?.[index]?.[item?.name]
                    : null
                  : custom === "bene"
                  ? beneficiaries?.[index]
                    ? beneficiaries[index]?.[item?.name]
                    : null
                  : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                  ? handleCustomParentChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : custom === "bene"
                  ? handleChangeBene(e, index)
                  : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 2:
          return item?.name?.toLowerCase()?.includes("mobile") ? (
            <div className="row">
              {width > 1024 ? (
                <>
                  <div className="col-4">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={
                        custom === "spouse" ? errors?.["spouseCountryCode"] : []
                      }
                      value={
                        custom === "spouse"
                          ? customAttributes?.spouseCountryCode
                          : inputs?.mobilePhoneCode
                      }
                      placeholder={"+267"}
                      onChange={(e) =>
                        custom === "child"
                          ? handleCustomChange(e, "mobilePhoneCode", index)
                          : custom === "parent"
                          ? handleCustomParentChange(
                              e,
                              "mobilePhoneCode",
                              index
                            )
                          : custom === "extended"
                          ? handleCustomExtendedChange(
                              e,
                              "mobilePhoneCode",
                              index
                            )
                          : custom === "spouse"
                          ? handleSpouseChange(e, null, item.code)
                          : handleChange(e, null, item.code)
                      }
                      required={true}
                      country={true}
                    />
                  </div>
                  <div className="col-8">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      name={item.name}
                      errors={
                        custom === "spouse"
                          ? errors?.[`${item?.name}`]
                          : errors?.["mobilePhone"]
                      }
                      type="number"
                      value={
                        custom === "child"
                          ? childState?.[index]
                            ? childState?.[index][item?.name]
                            : null
                          : custom === "parent"
                          ? parentState?.[index]
                            ? parentState?.[index]?.[item?.name]
                            : null
                          : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? extendedFamilyState?.[index]?.[item?.name]
                            : null
                          : item?.value
                      }
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 12) {
                          custom === "child"
                            ? handleCustomChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : custom === "parent"
                            ? handleCustomParentChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : custom === "extended"
                            ? handleCustomExtendedChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : handleChange(
                                e.target.value,
                                item.instanceId,
                                item.code
                              );
                        }
                      }}
                      required={true}
                      mobile={true}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={
                        custom === "spouse" ? errors?.["spouseCountryCode"] : []
                      }
                      value={
                        custom === "spouse"
                          ? customAttributes?.spouseCountryCode
                          : inputs?.mobilePhoneCode ?? "+267"
                      }
                      placeholder={"+267"}
                      onChange={(e) =>
                        custom === "child"
                          ? handleCustomChange(e, "mobilePhoneCode", index)
                          : custom === "parent"
                          ? handleCustomParentChange(
                              e,
                              "mobilePhoneCode",
                              index
                            )
                          : custom === "extended"
                          ? handleCustomExtendedChange(
                              e,
                              "mobilePhoneCode",
                              index
                            )
                          : custom === "spouse"
                          ? handleSpouseChange(e, null, item.code)
                          : handleChange(e, null, item.code)
                      }
                      required={true}
                      country={true}
                    />
                  </div>
                  <div className="col-12">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      name={item.name}
                      type="number"
                      errors={
                        custom === "spouse"
                          ? errors?.[`${item?.name}`]
                          : errors?.["mobilePhone"]
                      }
                      value={
                        custom === "child"
                          ? childState?.[index]
                            ? childState?.[index][item?.name]
                            : null
                          : custom === "parent"
                          ? parentState?.[index]
                            ? parentState?.[index]?.[item?.name]
                            : null
                          : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? extendedFamilyState?.[index]?.[item?.name]
                            : null
                          : item?.value
                      }
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 12) {
                          custom === "child"
                            ? handleCustomChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : custom === "parent"
                            ? handleCustomParentChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : custom === "extended"
                            ? handleCustomExtendedChange(
                                e.target.value,
                                item.name,
                                index
                              )
                            : handleChange(
                                e.target.value,
                                item.instanceId,
                                item.code
                              );
                        }
                      }}
                      required={true}
                      mobile={true}
                    />
                  </div>
                </>
              )}
            </div>
          ) : (
            <NumberInput
              id={item.name}
              label={item.description}
              errors={custom ? "" : errors[item.name]}
              name={item.name}
              placeholder={"+254123456789"}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index][item?.name]
                    : null
                  : custom === "parent"
                  ? parentState?.[index]
                    ? parentState?.[index]?.[item?.name]
                    : null
                  : custom === "extended"
                  ? extendedFamilyState?.[index]
                    ? extendedFamilyState?.[index]?.[item?.name]
                    : null
                  : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                  ? handleCustomParentChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              placeholder="dd/mm/yyyy"
              disabled={custom && custom === "extended" ? true : false}
              errors={
                custom
                  ? custom === "child"
                    ? errors[`Child DOB ${index + 1}`]
                    : custom === "spouse"
                    ? errors["Spouse DOB"]
                    : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              name={item.name}
              minDate={calculateMinDate(custom, item2?.max)}
              maxDate={calculateMaxDate(custom, item2?.min - 1)}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                  ? parentState?.[index]
                    ? parentState?.[index]?.[item?.name]
                    : null
                  : custom === "extended"
                  ? extendedFamilies?.[index]
                    ? extendedFamilies?.[index]?.dob
                    : null
                  : custom === "bene"
                  ? beneficiariesState?.[index]
                    ? beneficiariesState?.[index]?.[item?.name]
                    : null
                  : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                  ? handleCustomParentChange(e.target.value, item.name, index)
                  : custom === "extended"
                  ? handleCustomExtendedChange(e.target.value, item.name, index)
                  : custom === "bene"
                  ? handleChangeBene(e, index)
                  : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 7:
          if (
            item.settings.includes('["Yes","No"]') ||
            item.settings.includes('["NO","YES"]')
          ) {
            return <></>;
          }
          return item?.name?.toLowerCase().includes("gender") ? (
            <>
              <div style={{ height: "10px" }} />
              <TwoButtons
                id={item.name}
                title={item.description}
                isField={true}
                required={item.isRequired}
                half={true}
                errors={[]}
                state={
                  custom === "child"
                    ? childState?.[index]
                      ? childState?.[index]?.[item?.name]
                      : null
                    : custom === "parent"
                    ? parentState?.[index]
                      ? parentState?.[index]?.[item?.name]
                      : null
                    : custom === "extended"
                    ? extendedFamilyState?.[index]
                      ? extendedFamilyState?.[index]?.[item?.name]
                      : null
                    : custom === "bene"
                    ? beneficiariesState?.[index]
                      ? beneficiariesState?.[index]?.[item?.name]
                      : null
                    : item?.value
                }
                onClick={(e) =>
                  custom === "child"
                    ? handleCustomChange(e, item.name, index)
                    : custom === "parent"
                    ? handleCustomParentChange(e, item.name, index)
                    : custom === "extended"
                    ? handleCustomExtendedChange(e, item.name, index)
                    : custom === "bene"
                    ? handleChangeBene(e, index)
                    : handleChange(e, item.instanceId, item.code)
                }
                options={JSON.parse(item.settings)?.items?.map((x) => ({
                  name: x,
                  value: x,
                }))}
              />
              {(custom
                ? custom === "spouse"
                  ? errors["Spouse Gender"]
                  : errors[`${item.name} ${index + 1}`]
                : errors[item.name]
              )?.length && (
                <span className="text-danger errors" style={{ marginLeft: 0 }}>
                  {(custom
                    ? custom === "spouse"
                      ? errors["Spouse Gender"]
                      : errors[`${item.name} ${index + 1}`]
                    : errors[item.name]) &&
                    (custom
                      ? custom === "spouse"
                        ? errors["Spouse Gender"]
                        : errors[`${item.name} ${index + 1}`]
                      : errors[item.name])[0]}
                </span>
              )}
            </>
          ) : item.name.toLowerCase().includes("title") ? null : (
            <div className="d-flex flex-column mt-2">
              <label className="form-label mt-1">
                {item.name}
                {item?.isRequired ? "*" : null}
              </label>
              <ReactSelect
                id={item.name}
                placeholder={""}
                value={
                  custom === "child"
                    ? childState?.[index]
                      ? {
                          label: childState?.[index]?.[item?.name],
                          value: childState?.[index]?.[item?.name],
                        }
                      : null
                    : custom === "parent"
                    ? parentState?.[index]
                      ? {
                          label: parentState?.[index]?.[item?.name],
                          value: parentState?.[index]?.[item?.name],
                        }
                      : null
                    : custom === "extended"
                    ? extendedFamilyState?.[index]
                      ? {
                          label: extendedFamilyState?.[index]?.[item?.name],
                          value: extendedFamilyState?.[index]?.[item?.name],
                        }
                      : null
                    : custom === "bene"
                    ? beneficiariesState?.[index]
                      ? {
                          label: beneficiariesState?.[index]?.[item?.name],
                          value: beneficiariesState?.[index]?.[item?.name],
                        }
                      : null
                    : { label: item?.value, value: item?.value }
                }
                options={JSON.parse(item.settings)?.items?.map((x) => ({
                  label: x,
                  value: x,
                }))}
                defaultValue={{
                  label: JSON.parse(item.settings)?.items?.[0],
                  value: JSON.parse(item.settings)?.items?.[0],
                }}
                onChange={(e) =>
                  custom === "child"
                    ? handleCustomChange(e.value, item.name, index)
                    : custom === "parent"
                    ? handleCustomParentChange(e.value, item.name, index)
                    : custom === "extended"
                    ? handleCustomExtendedChange(e.value, item.name, index)
                    : custom === "bene"
                    ? handleChangeBene(e.value, index)
                    : handleChange(e.value, item.instanceId, item.code)
                }
              />
              {(custom
                ? errors[`${item.name} ${index + 1}`]
                : errors[item.name]
              )?.length && (
                <span className="text-danger errors" style={{ marginLeft: 0 }}>
                  {(custom
                    ? errors[`${item.name} ${index + 1}`]
                    : errors[item.name]) &&
                    (custom
                      ? errors[`${item.name} ${index + 1}`]
                      : errors[item.name])[0]}
                </span>
              )}
            </div>
          );
      }
    },
    [errors, riskAttributes]
  );
  const { width } = useWindowDimensions();

  const accordionState = useSelector((state) => state?.products?.accordion);

  const [expand, setExpand] = useState({});

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  const quqRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const premiumAmount = useSelector(
    (state) =>
      state?.products?.executeCalculator?.productOptions?.[0]?.risks?.[0]
        ?.rating?.total
  );

  const executeCalculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const getAttributes = (value) => {
    return quqRiskAttributes?.find((x) => x.name === value);
  };

  const reducerState = useSelector((state) => state?.products);

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const [isLoading, setIsLoading] = useState(false);

  const getConstraints = () => {
    var val = {};
    if (customAttribues?.spouse_cover === 0) {
      riskAttributes
        .find((x) => x.code === "SPO")
        ?.attributes?.filter((f) => !f.name.toLowerCase().includes("title"))
        .forEach((y, i) => {
          if (
            y.name === "Spouse Option ID or Passport" ||
            y.name === "Spouse ID Number or Passport no"
          )
            return;
          if (y.name === "Spouse Name") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
            };
            if (y.name === "Spouse Mobile") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ Spouse mobile number can't be blank",
                  },
                },
              };
            }
          } else if (y.name === "Spouse Surname") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
            };
          } else if (y.name === "Spouse Mobile") {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Spouse mobile number can't be blank",
                },
              },
            };
          } else {
            val = {
              ...val,
              [y.name]: {
                presence: {
                  allowEmpty: false,
                },
              },
            };
          }
        });
    }
    if (customAttribues?.child_cover === 0) {
      getRows(customAttribues?.numberOfChildren).map((x, i) => {
        val = {
          ...val,
          [`Child First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },
          [`Child Surname ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },
          [`Child DOB ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },

          [`Child Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      });
    }
    if (
      riskAttributes
        ?.find((x) => x.code === RISKS.QUQ)
        ?.attributes?.find((z) => z.name === ATTRIBUTES.EXTENDED_FAMILY)
        ?.value === "YES"
    ) {
      getExtended().map((x, i) => {
        val = {
          ...val,
          [`Extended Family First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },
          [`Extended Family Surname ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },

          [`Extended Family Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      });
    }
    if (totalParents() > 0) {
      val = {
        ...val,
        [`Parent First Name 1`]: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[A-Za-z /-]*$/g,
            message:
              "^Only alphabetic characters, hyphen and space are allowed",
          },
        },
        [`Parent Surname 1`]: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[A-Za-z /-]*$/g,
            message:
              "^Only alphabetic characters, hyphen and space are allowed",
          },
        },
        [`Parent Gender 1`]: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[A-Za-z]+$/,
            message: "can only contain alphabets",
          },
        },
        [`Parent Date of Birth 1`]: {
          presence: {
            allowEmpty: false,
          },
        },
        [`Parent Date of Birth 1`]: {
          presence: {
            allowEmpty: false,
          },
        },

        [`Parent Gender 1`]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
    }
    if (beneficiaries?.length) {
      getRows(beneCount)?.map((x, i) => {
        val = {
          ...val,
          [`Beneficiary First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },
          [`Beneficiary Last Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z /-]*$/g,
              message:
                "^Only alphabetic characters, hyphen and space are allowed",
            },
          },
          [`Beneficiary Date of Birth ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
          [`Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
          [`Phone Number ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^ Beneficiary mobile number can’t be blank",
            },
            format: {
              pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
              message: "^ Invalid mobile number",
            },
          },
          [`Percentage ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
          [`Phone Number Code ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^Country code can't be blank",
            },
            length: {
              maximum: 4,
              message: "^Country code is too long (maximum is 4 characters)",
            },
          },
          [`Telephone Number Code ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            length: {
              maximum: 4,
              message:
                "^Telephone Number Code is too long (maximum is 4 characters)",
            },
          },
        };
        
        if (beneficiaries[i]?.["email"]) {
          val = {
            ...val,
            [`Email ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              email: {
                message: "^ Email is not a valid email",
              },
            },
          };
        }
        if (calculateage(beneficiaries[i]?.["dob"])) {
          val = {
            ...val,

            [`Trustee First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Trustee Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Trustee Phone Number ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Trustee mobile number can’t be blank",
              },
            },
            [`Trustee Phone Number Code ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Country code can't be blank",
              },
              length: {
                maximum: 4,
                message: "^Country code is too long (max is 4 characters)",
              },
            },
            [`Trustee dob ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
      });
    }

    return val;
  };

  const getConstraintsOver60 = () => {
    var val = {};
    var option = getAttributes("Cover Option 60 Plus")?.value;

    if (option === "Individual") {
      if (beneficiaries?.length) {
        getRows(beneCount)?.map((x, i) => {
          val = {
            ...val,
            [`Beneficiary First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Beneficiary Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },

            [`Beneficiary Date of Birth ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Gender ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Phone Number ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Beneficiary mobile number can’t be blank",
              },
              format: {
                pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
                message: "^ Invalid mobile number",
              },
            },
            [`Phone Number Code ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Country code can't be blank",
              },
              length: {
                maximum: 4,
                message: "^Country code is too long (max is 4 characters)",
              },
            },
            [`Percentage ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
          if (beneficiaries[i]?.["Telephone Number Code"]) {
            val = {
              ...val,
              [`Telephone Number Code ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                length: {
                  maximum: 4,
                  message: "^ Invalid country code",
                },
              },
            };
          }
          if (beneficiaries[i]?.["email"]) {
            val = {
              ...val,
              [`Email ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                email: {
                  message: "^ Email is not a valid email",
                },
              },
            };
          }
          if (calculateage(beneficiaries[i]?.["dob"])) {
            val = {
              ...val,
              [`Trustee First Name ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
              [`Trustee Last Name ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
              [`Trustee Phone Number ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Trustee mobile number can’t be blank",
                },
              },
              [`Trustee Phone Number Code ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Country code can't be blank",
                },
                length: {
                  maximum: 4,
                  message: "^Country code is too long (max is 4 characters)",
                },
              },
              [`Trustee dob ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
              },
            };
          }
        });
      }
    } else {
      if (customAttribues?.spouse_cover === 0) {
        riskAttributes
          .find((x) => x.code === "SPO")
          ?.attributes?.filter((f) => !f.name.toLowerCase().includes("title"))
          .forEach((y, i) => {
            if (
              y.name === "Spouse Option ID or Passport" ||
              y.name === "Spouse ID Number or Passport no"
            )
              return;
            if (y.name === "Spouse Name") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message:
                      "^Only alphabetic characters, hyphen and space are allowed",
                  },
                },
              };
              if (y.name === "Spouse Mobile") {
                val = {
                  ...val,
                  [y.name]: {
                    presence: {
                      allowEmpty: false,
                      message: "^ Spouse mobile number can't be blank",
                    },
                  },
                };
              }
            } else if (
              y.name === "Spouse Last Name" ||
              y.name === "Spouse Surname"
            ) {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message:
                      "^Only alphabetic characters, hyphen and space are allowed",
                  },
                },
              };
            } else if (y.name === "Spouse Mobile") {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                    message: "^ Spouse mobile number can't be blank",
                  },
                },
              };
              val = {
                ...val,
                spouseCountryCode: {
                  presence: {
                    allowEmpty: false,
                    message: "^Country code can't be blank",
                  },
                  length: {
                    maximum: 4,
                    message:
                      "^ Mobile phone code is too long (max is 4 characters)",
                  },
                },
              };
            } else {
              val = {
                ...val,
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              };
            }
          });
      }
      if (customAttribues?.child_cover === 0) {
        getRows(customAttribues?.numberOfChildren).map((x, i) => {
          val = {
            ...val,
            [`Child First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Child Surname ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Child DOB ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },

            [`Child Gender ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        });
      }
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.QUQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.EXTENDED_FAMILY)
          ?.value === "YES"
      ) {
        getExtended().map((x, i) => {
          val = {
            ...val,
            [`Extended Family First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Extended Family Surname ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Extended Family date of Birth ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },

            [`Extended Family Gender ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        });
      }

      if (beneficiaries?.length) {
        getRows(beneCount)?.map((x, i) => {
          val = {
            ...val,
            [`Beneficiary First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },
            [`Beneficiary Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z /-]*$/g,
                message:
                  "^Only alphabetic characters, hyphen and space are allowed",
              },
            },

            [`Beneficiary Date of Birth ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Gender ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Phone Number ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Beneficiary mobile number can’t be blank",
              },
              format: {
                pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
                message: "^ Invalid mobile number",
              },
            },
            [`Phone Number Code ${i + 1}`]: {
              presence: {
                allowEmpty: false,
                message: "^ Country code can't be blank",
              },
            },
            [`Percentage ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
          if (beneficiaries[i]?.["Telephone Number Code"]) {
            val = {
              ...val,
              [`Telephone Number Code ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                length: {
                  maximum: 4,
                  message: "^ Invalid country code",
                },
              },
            };
          }
          if (beneficiaries[i]?.["Phone Number Code"]) {
            val = {
              ...val,
              [`Phone Number Code ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Country code can't be blank",
                },
                length: {
                  maximum: 4,
                  message: "^ Invalid country code",
                },
              },
            };
          }
          if (beneficiaries[i]?.["email"]) {
            val = {
              ...val,
              [`Email ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                email: {
                  message: "^ Email is not a valid email",
                },
              },
            };
          }
          if (calculateage(beneficiaries[i]?.["dob"])) {
            val = {
              ...val,
              [`Trustee First Name ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
              [`Trustee Last Name ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
                format: {
                  pattern: /^[A-Za-z /-]*$/g,
                  message:
                    "^Only alphabetic characters, hyphen and space are allowed",
                },
              },
              [`Trustee Phone Number ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Trustee mobile number can’t be blank",
                },
              },
              [`Trustee Phone Number Code ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Country code can't be blank",
                },
                length: {
                  maximum: 4,
                  message: "^ Invalid country code",
                },
              },

              [`Trustee dob ${i + 1}`]: {
                presence: {
                  allowEmpty: false,
                },
              },
            };
          }
        });
      }
    }
    return val;
  };

  var getAttr = function () {
    let attrVal = [];
    Object.entries(inputs)
      .filter((x) => x?.length < 15)
      .forEach(([key, value]) => {
        if (key === "maritalStatus") {
          attrVal.push({ name: key, value: value.value });
        } else {
          attrVal.push({ name: key, value: value });
        }
      });
    return attrVal;
  };

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  const over60Option = getAttributes("Cover Option 60 Plus");

  const [policyExpand, setPolicyExpand] = useState(false);
  const [spouseExpand, setSpouseExpand] = useState(false);
  const [childExpand, setChildExpand] = useState(false);
  const [extendedExpand, setExtendedExpand] = useState(false);
  const [parentExpand, setParentExpand] = useState(false);
  const [beneExpand, setBeneExpand] = useState(false);

  const [idCount, setIdCount] = useState(0);

  const getIDNumber = () => {
    return `${inputs?.nationalityId}-${idCount - (idCount - 1)}`;
  };

  const getUpdatedAttributes = (count) => {
    return new Promise((resolve, reject) => {
      var arr = [
        {
          externalReference: uuidv4(),
          code: "SPO",
          attributes: [
            ...reducerState.riskAttributes
              .find((x) => x.code === "SPO")
              ?.attributes.map((i) => ({
                ...i,
                value: i.name.toLowerCase().includes("title")
                  ? reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.find((q) =>
                        q.name.toLowerCase().includes("gender")
                      )?.value === "Male"
                    ? "Mr"
                    : "Miss"
                  : i.value,
              })),
          ],
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "SPO"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        },
        ...childAttributes.map((x, i) => ({
          attributes: [
            ...Object.keys(x).map((y) => ({
              name: y,
              value: x[y],
            })),
            {
              name: reducerState.riskAttributes
                .find((x) => x.code === "CHD")
                ?.attributes.find((g) => g.name.toLowerCase().includes("title"))
                ?.name,
              value: x["Child Gender"] === "Male" ? "Mr" : "Miss",
            },
            {
              name: "Child ID Number or Passport no",
              value: getIDNumber(count, "child", i),
            },
            {
              name: "Child Option ID or Passport",
              value: "ID Number",
            },
          ],
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "CHD"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        })),
        ...parentState
          ?.filter((f) => f !== null)
          ?.map((x, i) => ({
            attributes: [
              ...Object?.keys(x || {})
                ?.filter((t) => x?.[t])
                ?.map((y) => ({ name: y, value: x[y] })),
              {
                name: reducerState.riskAttributes
                  .find((x) => x.code === "PAR")
                  ?.attributes.find((g) =>
                    g.name.toLowerCase().includes("title")
                  )?.name,
                value: x?.["Parent Gender"] === "Male" ? "Mr" : "Miss",
              },
              {
                name: "Parent ID Number or Passport no",
                value: getIDNumber(count, "parent", i),
              },
              {
                name: "Parent Option ID or Passport",
                value: "ID Number",
              },
            ],
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "PAR"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),
        ...extendedFamilyAttributes.map((x, i) => ({
          attributes: [
            ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
            {
              name: reducerState.riskAttributes
                .find((x) => x.code === "EXF")
                ?.attributes.find((g) => g.name.toLowerCase().includes("title"))
                ?.name,
              value: x["Extended Family Gender"] === "Male" ? "Mr" : "Miss",
            },
            {
              name: "Extended Family date of Birth",
              value: extendedFamilies?.[i]?.dob,
            },
            {
              name: "Extended Family ID Number or Passport no",
              value: getIDNumber(count, "extended", i),
            },
            {
              name: "Extended Family Option ID or Passport",
              value: "ID Number",
            },
          ],
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "EXF"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        })),
      ];

      let arr2 = [];
      getRows(arr?.length)?.map((r, i) => {
        var temp = [];
        if (arr?.[i]?.attributes?.length) {
          temp = [...arr?.[i]?.attributes];
        }
        if (arr[i]?.attributes?.[0]?.code === "SPO") {
          temp[6] = { ...temp[6], value: `${inputs?.nationalityId}-${i + 1}` };
          temp[5] = { ...temp[5], value: "ID Number" };
          arr2.push({
            ...arr[i],
            attributes: temp,
          });
        } else {
          temp[5] = { ...temp[5], value: `${inputs?.nationalityId}-${i + 1}` };
          arr2.push({
            ...arr[i],
            attributes: temp,
          });
        }
        if (getRows(arr?.length)?.length - 1 === i) {
          resolve(arr2);
        }
      });
    });
  };

  const handleSubmit = async () => {
    var values = {};
    values = {
      ...inputs,
      spouseCountryCode: customAttributes?.spouseCountryCode,
    };
    let spouseAtt = [];
    let count =
      parseInt(customAttributes?.numberOfChildren) +
      getExtended()?.length +
      totalParents();

    setIdCount(count);

    const getAttries = await getUpdatedAttributes(count);

    riskAttributes
      ?.find((x) => x.code === "SPO")
      ?.attributes?.forEach((t) => {
        spouseAtt.push(t.name);
        values = { ...values, [t.name]: t.value };
      });

    let childAtt = [];

    getRows(customAttributes?.numberOfChildren).map((x, i) => {
      childAtt.push(`Child First Name ${i + 1}`);
      childAtt.push(`Child Surname ${i + 1}`);
      childAtt.push(`Child Gender ${i + 1}`);
      childAtt.push(`Child ID Number or Passport no ${i + 1}`);
      childAtt.push(`Child DOB ${i + 1}`);
      childAtt.push(`Child Option ID or Passport ${i + 1}`);
      values = {
        ...values,
        [`Child First Name ${i + 1}`]: childState[i]?.[`Child First Name`],
        [`Child Surname ${i + 1}`]: childState[i]?.[`Child Surname`],
        [`Child Gender ${i + 1}`]: childAttributes[i]?.[`Child Gender`],
        [`Child ID Number or Passport no ${i + 1}`]: `${inputs?.idNumber}-${
          count - (count - 1)
        }`,
        [`Child Option ID or Passport ${i + 1}`]: "ID Number",
        [`Child DOB ${i + 1}`]: childState[i]?.[`Child Date of Birth`],
      };
    });

    let extendedAtt = [];

    if (getExtended()?.length) {
      getExtended().map((x, i) => {
        extendedAtt.push(`Extended Family First Name ${i + 1}`);
        extendedAtt.push(`Extended Family Surname ${i + 1}`);
        extendedAtt.push(`Extended Family date of Birth ${i + 1}`);
        extendedAtt.push(`Extended Family ID Number or Passport no ${i + 1}`);
        extendedAtt.push(`Extended Family Gender ${i + 1}`);
        extendedAtt.push(`Extended Family Option ID or Passport ${i + 1}`);
        values = {
          ...values,
          [`Extended Family First Name ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family First Name`],
          [`Extended Family Surname ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family Surname`],
          [`Extended Family date of Birth ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family date of Birth`],
          [`Extended Family ID Number or Passport no ${i + 1}`]:
            extendedFamilyState[i]?.[
              `Extended Family ID Number or Passport no`
            ],
          [`Extended Family Gender ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family Gender`],
          [`Extended Family Option ID or Passport ${i + 1}`]: "ID Number",
        };
      });
    }

    let parentAtt = [];

    getRows(totalParents()).map((x, i) => {
      parentAtt.push(`Parent First Name ${i + 1}`);
      parentAtt.push(`Parent Surname ${i + 1}`);
      parentAtt.push(`Parent Date of Birth ${i + 1}`);
      parentAtt.push(`Parent ID Number or Passport no ${i + 1}`);
      parentAtt.push(`Parent Gender ${i + 1}`);
      parentAtt.push(`Parent Option ID or Passport ${i + 1}`);
      values = {
        ...values,
        [`Parent First Name ${i + 1}`]: parentState[i]?.[`Parent First Name`],
        [`Parent Surname ${i + 1}`]: parentState[i]?.[`Parent Surname`],
        [`Parent Date of Birth ${i + 1}`]:
          parentState[i]?.[`Parent Date of Birth`],
        [`Parent ID Number or Passport no ${i + 1}`]:
          parentState[i]?.[`Parent ID Number or Passport no`],
        [`Parent Gender ${i + 1}`]: parentState[i]?.[`Parent Gender`],
        [`Parent Option ID or Passport ${i + 1}`]: "ID Number",
      };
    });

    let beneAttributes = [];

    if (beneficiariesState?.length) {
      getRows(beneCount).map((x, i) => {
        beneAttributes.push(`Beneficiary First Name ${i + 1}`);
        beneAttributes.push(`Last Name ${i + 1}`);
        beneAttributes.push(`Email ${i + 1}`);
        beneAttributes.push(`Percentage ${i + 1}`);
        values = {
          ...values,
          [`Beneficiary First Name ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]?.["firstName"]
            : null,
          [`Beneficiary Last Name ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]?.["lastName"]
            : null,
          [`Email ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})?.length
            ? beneficiariesState[i]["email"]
            : null,
          [`Phone Number ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})
            ?.length
            ? beneficiariesState[i]["number"]
            : null,
          [`Phone Number Code ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["Phone Number Code"]
            : null,
          [`Percentage ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})
            ?.length
            ? beneficiariesState[i]["percentage"]
            : null,
          [`Telephone number ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["telephoneNumber"]
            : null,
          [`Beneficiary Date of Birth ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]?.["dob"]
            : null,
          [`Postal Address ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})
            ?.length
            ? beneficiariesState[i]["postalAddress"]
            : null,
          [`Gender ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})?.length
            ? beneficiariesState[i]["gender"]
            : null,

          [`Trustee First Name ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["trusteeName"]
            : null,
          [`Telephone Number Code ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["Telephone Number Code"]
            : null,
          [`Trustee Last Name ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["trusteeLastName"]
            : null,
          [`Trustee dob ${i + 1}`]: Object.keys(beneficiariesState[i] ?? {})
            ?.length
            ? beneficiariesState[i]["trusteeDob"]
            : null,
          [`Trustee Phone Number ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["trusteeNumber"]
            : null,
          [`Trustee Phone Number Code ${i + 1}`]: Object.keys(
            beneficiariesState[i] ?? {}
          )?.length
            ? beneficiariesState[i]["Trustee Phone Number Code"]
            : null,
        };
      });
    }

    var constraints = {};

    if (inputs?.eMail) {
      constraints = {
        ...constraints,
        eMail: {
          presence: {
            allowEmpty: false,
          },
          email: {
            message: "^ Email is not a valid email",
          },
        },
      };
    }
    if (inputs?.address2) {
      constraints = {
        ...constraints,
        address2: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[-\sa-zA-Z0-9]+$/,
            message:
              "^ Only alphabetic characters, number, hyphen and space are allowed",
          },
        },
      };
    }

    if (inputs?.postalAddress) {
      constraints = {
        ...constraints,
        postalAddress: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[-\sa-zA-Z0-9]+$/,
            message:
              "^ Only alphabetic characters, number, hyphen and space are allowed",
          },
        },
      };
    }

    if (inputs?.postalCity) {
      constraints = {
        ...constraints,
        postalCity: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[-\sa-zA-Z]+$/,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
        },
      };
    }

    if (inputs?.postalProvince) {
      constraints = {
        ...constraints,
        postalProvince: {
          presence: {
            allowEmpty: false,
          },
          format: {
            pattern: /^[-\sa-zA-Z]+$/,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
        },
      };
    }
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
      constraints = {
        ...constraints,
        ...getConstraints(),
      };
      if (inputs?.landlinePhoneCode) {
        constraints = {
          ...constraints,
          landlinePhoneCode: {
            presence: {
              allowEmpty: false,
            },
            length: {
              maximum: 4,
            },
          },
        };
      }

      if (customAttributes?.spouse_cover === 0) {
        constraints = {
          ...constraints,
          spouseCountryCode: {
            presence: {
              allowEmpty: false,
            },
            length: {
              maximum: 4,
              message: "^ Country code is too long (max is 4 characters)",
            },
          },
        };
        values = {
          ...values,
          spouseCountryCode: customAttributes?.spouseCountryCode,
        };
      }
    } else {
      constraints = { ...constraints, ...getConstraintsOver60() };
    }

    let parentIndexes = [];
    if (totalParents()) {
      getRows(totalParents())?.map((item, index) => {
        if (index === 0) return;
        if (parentState?.[index]) {
          Object?.keys(parentState[index])?.map((x, i) => {
            if (parentState[index]?.[x]?.length) {
              parentIndexes = [...parentIndexes, index];

              dispatch(
                updateCustomAttributes({
                  type: "PARENT_INDEXES",
                  payload: parentIndexes,
                })
              );
              constraints = {
                ...constraints,
                [`Parent First Name ${index + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message:
                      "^Only alphabetic characters, hyphen and space are allowed",
                  },
                },
                [`Parent Surname ${index + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message:
                      "^Only alphabetic characters, hyphen and space are allowed",
                  },
                },
                [`Parent Gender ${index + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z]+$/,
                    message: "can only contain alphabets",
                  },
                },
                [`Parent Date of Birth ${index + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },

                [`Parent Gender ${index + 1}`]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              };
            }
          });
        }
      });
    }

    let error = validate(values, { ...schema, ...constraints });

    if (inputs?.nationalityId) {
      if (inputs?.nationality?.toLowerCase().includes("botswana")) {
        if (
          (parseInt(inputs?.nationalityId[4]) === 1 &&
            inputs.mainGender === "Male") ||
          (parseInt(inputs?.nationalityId[4]) === 2 &&
            inputs.mainGender === "Female")
        ) {
        } else {
          error = {
            ...error,
            nationalityId: error?.nationalityId
              ? [...error?.nationalityId, "Invalid nationality id"]
              : ["Invalid nationality id"],
          };
        }
      }
    }

    if (!files?.omang_card_front) {
      error = {
        ...error,
        omang_card_front: ["Omang card front is required"],
      };
    } else {
      delete error?.omang_card_front;
    }
    if (!files?.omang_card_back) {
      error = {
        ...error,
        omang_card_back: ["Omang card back is required"],
      };
    } else {
      delete error?.omang_card_back;
    }

    if (error || error !== undefined) {
      if (beneficiaries?.length) {
        Object.keys(error)?.map((x) => {
          if (beneAttributes?.includes(x)) {
            setExpand({ ...expand, beneficiaries: true });
            setBeneExpand(true);
            error = {
              ...error,
              beneficiaries: ["Please fill beneficiaries details "],
            };
          }
        });
      }
    }

    var mainMemberAttributes = [
      "firstName",
      "lastName",
      "nationalityId",
      "dob",
      "nationality",
      "omang_card_front",
      "omang_card_back",
      "mainGender",
      "maritalStatus",
      "mobilePhone",
      "physicalAddress",
      "region",
      "eMail",
      "city",
    ];

    if (error || error !== undefined) {
      if (Object.keys(inputs)?.length) {
        Object.keys(error)?.map((x) => {
          if (mainMemberAttributes?.includes(x)) {
            setPolicyExpand(true);
            setExpand({ ...expand, policy: true });

            error = {
              ...error,
              policy: ["Please fill policy holder details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (getRows(customAttributes?.numberOfChildren)?.length) {
        Object.keys(error)?.map((x) => {
          if (childAtt?.includes(x)) {
            setExpand({ ...expand, child: true });
            setChildExpand(true);
            error = {
              ...error,
              child: ["Please fill child details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (customAttributes?.spouse_cover === 0) {
        Object.keys(error)?.map((x) => {
          if (spouseAtt?.includes(x)) {
            setSpouseExpand(true);
            setExpand({ ...expand, spouse: true });
            error = {
              ...error,
              spouse: ["Please fill spouse details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (getExtended()?.length) {
        Object.keys(error)?.map((x) => {
          if (extendedAtt?.includes(x)) {
            setExtendedExpand(true);
            setExpand({ ...expand, extended: true });
            error = {
              ...error,
              extended: ["Please fill extended family details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (getRows(totalParents())?.length) {
        Object.keys(error)?.map((x) => {
          if (parentAtt?.includes(x)) {
            setParentExpand(true);
            error = {
              ...error,
              parent: ["Please fill parents details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      console.log(error);
      var errorArray = Object.keys(error);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      return setErrors(error);
    }

    const beneficiariesCount = beneficiaries.reduce(
      (a, b) => a + b.percentage,
      0
    );

    if (beneficiariesCount !== 100) {
      return setErrors({
        ...errors,
        ["beneficiariesSplit"]: [
          `Benefit split for all beneficiaries must be total to 100%`,
        ],
      });
    }

   
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
       //trackUserEvents
      trackUserEvents("bw_complete_your_details_next_cta", 
                  {firstName: inputs?.firstName,
                  lastName: inputs?.lastName,
                  birthDate: inputs?.dob,
                  title: inputs?.mainGender === "Male" ? "Mr" : "Miss",
                  mobilePhone: inputs?.mobilePhone,
                  mobilePhoneCode:
                    mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
                  nationalityId: inputs?.nationalityId,
                  nationality: inputs?.nationality,
                  incomeTax: inputs?.incomeTax,
                  marriageStatus: inputs?.maritalStatus?.value,
                  gender: inputs?.mainGender,
                  telePhone: inputs?.telePhone,
                  address: inputs?.physicalAddress,
                  address2: inputs?.address2,
                  city: inputs?.city,
                  province: inputs?.region,
                  postalAddress: inputs?.postalAddress,
                  postalCity: inputs?.postalCity,
                  postalProvince: inputs?.postalProvince,
                  workGPSCoordinate: inputs?.occupation,
                  landlinePhone: inputs?.landlinePhone,
                  landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace(
                    "+",
                    ""
                  )}`,
                  eMail: inputs?.eMail?.length
                    ? inputs?.eMail
                    : "clientservices@blil.co.bw",
                  memberType: 1,
                  relationship: 0,
                });
      return handleNext();
    }

    var policyRisks =
      over60Option?.value === "Individual"
        ? [
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes
                  .find((x) => x.code === "QUQ")
                  ?.attributes.map((y) => ({
                    dataType: y.dataType,
                    name: y.name,
                    instanceId: y.instanceId,
                    value:
                      y.name === "Date of Birth"
                        ? customAttributes?.dob ?? 0
                        : y.name === "Number of Children"
                        ? customAttributes?.numberOfChildren ?? 0
                        : y.name === "Extended Family Aged 1-15 Years"
                        ? bands?.find((x) => x.name === "Aged from 1-15")?.value
                        : y.name === "Extended Family Aged 16-25 Years"
                        ? bands?.find((x) => x.name === "Aged from 16-25")
                            ?.value
                        : y.name === "Extended Family Aged 26-35 Years"
                        ? bands?.find((x) => x.name === "Aged from 26-35")
                            ?.value
                        : y.name === "Extended Family Aged 36-45 Years"
                        ? bands?.find((x) => x.name === "Aged from 36-45")
                            ?.value
                        : y.name === "Extended Family Aged 46-55 Years"
                        ? bands?.find((x) => x.name === "Aged from 46-55")
                            ?.value
                        : y.name === "Extended Family Aged 56-65 Years"
                        ? bands?.find((x) => x.name === "Aged from 56-65")
                            ?.value
                        : y.name === "Extended Family Aged 66-75 Years"
                        ? bands?.find((x) => x.name === "Aged from 66-75")
                            ?.value
                        : y.name === "Extended Family Aged 76-85 Years"
                        ? bands?.find((x) => x.name === "Aged from 76-85")
                            ?.value
                        : y.value ?? null,
                  })),
              ],
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "QUQ"
              )?.instanceId,
              price: premiumAmount,
              discount: 0,

              tax: 0,
              total: premiumAmount,
            },
            {
              attributes: getAttr(),
              externalReference: uuidv4(),
              valuations:
                customAttributes?.spouse_cover === 0
                  ? executeCalculatorResponse?.productOptions?.[0]?.valuations
                      ?.values
                  : executeCalculatorResponse?.productOptions?.[0]?.valuations?.values?.filter(
                      (x) => x.name !== "Spouse"
                    ),
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "MLI"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
          ]
        : [
            {
              externalReference: uuidv4(),
              attributes: [
                ...reducerState.riskAttributes
                  .find((x) => x.code === "QUQ")
                  ?.attributes.map((y) => ({
                    dataType: y.dataType,
                    name: y.name,
                    instanceId: y.instanceId,
                    value:
                      y.name === "Date of Birth"
                        ? customAttributes?.dob ?? 0
                        : y.name === "Number of Children"
                        ? customAttributes?.numberOfChildren ?? 0
                        : y.name === "Extended Family Aged 1-15 Years"
                        ? bands?.find((x) => x.name === "Aged from 1-15")?.value
                        : y.name === "Extended Family Aged 16-25 Years"
                        ? bands?.find((x) => x.name === "Aged from 16-25")
                            ?.value
                        : y.name === "Extended Family Aged 26-35 Years"
                        ? bands?.find((x) => x.name === "Aged from 26-35")
                            ?.value
                        : y.name === "Extended Family Aged 36-45 Years"
                        ? bands?.find((x) => x.name === "Aged from 36-45")
                            ?.value
                        : y.name === "Extended Family Aged 46-55 Years"
                        ? bands?.find((x) => x.name === "Aged from 46-55")
                            ?.value
                        : y.name === "Extended Family Aged 56-65 Years"
                        ? bands?.find((x) => x.name === "Aged from 56-65")
                            ?.value
                        : y.name === "Extended Family Aged 66-75 Years"
                        ? bands?.find((x) => x.name === "Aged from 66-75")
                            ?.value
                        : y.name === "Extended Family Aged 76-85 Years"
                        ? bands?.find((x) => x.name === "Aged from 76-85")
                            ?.value
                        : y.value ?? null,
                  })),
              ],
              valuations:
                customAttributes?.spouse_cover === 0
                  ? executeCalculatorResponse?.productOptions?.[0]?.valuations
                      ?.values
                  : executeCalculatorResponse?.productOptions?.[0]?.valuations?.values?.filter(
                      (x) => x.name !== "Spouse"
                    ),
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "QUQ"
              )?.instanceId,
              price: premiumAmount,
              discount: 0,

              tax: 0,
              total: premiumAmount,
            },
            ...getAttries,

            {
              attributes: getAttr(),
              externalReference: uuidv4(),
              valuations: [],
              productRiskReference: reducerState.riskAttributes.find(
                (x) => x.code === "MLI"
              )?.instanceId,
              price: 0.0,
              discount: 0.0,
              tax: 0.0,
              total: 0.0,
            },
          ];
    if (customAttributes?.spouse_cover === 1) {
      policyRisks.splice(1, 1);
    }
    try {
      setIsLoading(true);
      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: null,
              endDate: null,
              PolicyStatus: "Quoted",
              currencyCode: "BWP",
              members: [
                {
                  firstName: inputs?.firstName,
                  lastName: inputs?.lastName,
                  birthDate: inputs?.dob,
                  title: inputs?.mainGender === "Male" ? "Mr" : "Miss",
                  mobilePhone: inputs?.mobilePhone,
                  mobilePhoneCode:
                    mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
                  nationalityId: inputs?.nationalityId,
                  nationality: inputs?.nationality,
                  incomeTax: inputs?.incomeTax,
                  marriageStatus: inputs?.maritalStatus?.value,
                  gender: inputs?.mainGender,
                  telePhone: inputs?.telePhone,
                  address: inputs?.physicalAddress,
                  address2: inputs?.address2,
                  city: inputs?.city,
                  province: inputs?.region,
                  postalAddress: inputs?.postalAddress,
                  postalCity: inputs?.postalCity,
                  postalProvince: inputs?.postalProvince,
                  workGPSCoordinate: inputs?.occupation,
                  landlinePhone: inputs?.landlinePhone,
                  landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace(
                    "+",
                    ""
                  )}`,
                  eMail: inputs?.eMail?.length
                    ? inputs?.eMail
                    : "clientservices@blil.co.bw",
                  memberType: 1,
                  relationship: 0,
                },
              ],

              beneficiaries: beneficiaries.reduce((acc, x) => {
                acc = [
                  ...acc,
                  {
                    beneficiaryType: 0,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    country: x.country,
                    dob: x.dob,
                    birthDate: x.dob,
                    title: x.gender === "Male" ? "Mr" : "Miss",
                    nationalityId: x.nationalityId,
                    eMail: x.email?.length
                      ? x.email
                      : "clientservices@blil.co.bw",
                    landlinePhone: x.telephoneNumber,
                    landlinePhoneCode: `+${x?.[
                      "Telephone Number Code"
                    ]?.replace("+", "")}`,
                    postalAddress: inputs?.postalAddress,
                    gender: x.gender,
                    mobilePhone: x.number,
                    address: inputs?.postalAddress,
                    address1: inputs?.city,
                    address2: inputs?.region,
                    mobilePhoneCode: `+${x?.["Phone Number Code"]?.replace(
                      "+",
                      ""
                    )}`,
                    split: x.percentage / 100,
                  },
                ];
                if (x.trusteeName) {
                  acc = [
                    ...acc,
                    {
                      beneficiaryType: 2,
                      firstName: x.trusteeName,
                      lastName: x.trusteeLastName,
                      dob: x.trusteeDob,
                      birthDate: x.trusteeDob,
                      mobilePhone: x.trusteeNumber,
                      mobilePhoneCode: `+${x?.[
                        "Trustee Phone Number Code"
                      ]?.replace("+", "")}`,
                    },
                  ];
                }
                return acc;
              }, []),
              attributes: [],
              risks: policyRisks,
              bankAccounts: null,
              productOptionReference:
                reducerState?.executeCalculator?.productOptions?.[0]
                  ?.productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "856c0883-2794-b487-fed5-d14f92bfa022"
              : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? "e83501db-1c35-278e-8c51-e276d7ac99b8"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );
      let body = {
        externalReference: "CREATE_SALE_300",
        policySaleReference: res?.data?.data?.policySaleReference,
        policyAttachments: [
          {
            externalReference: "OMANG",
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            Attachments: [
              {
                instanceId: "100",
                fileName: files?.omang_card_front?.name?.split(".")[0],
                fileExtension: files?.omang_card_front?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "OFID"
                ).instanceId,
              },
              {
                instanceId: "101",
                fileName: files?.omang_card_back?.name?.split(".")[0],
                fileExtension: files?.omang_card_back?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "OBID"
                ).instanceId,
              },
            ],
          },
        ],
      };
      let file = [files?.omang_card_front, files?.omang_card_back];
      let tags = Array(2)
        .fill()
        .map((_, i) => (100 + i).toString());
      await dispatch(policySaleAttachments({ body, file, tags }));
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("bw_complete_your_details_next_cta", {
        firstName: inputs?.firstName,
        lastName: inputs?.lastName,
        birthDate: inputs?.dob,
        title: inputs?.mainGender === "Male" ? "Mr" : "Miss",
        mobilePhone: inputs?.mobilePhone,
        mobilePhoneCode: mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
        nationalityId: inputs?.nationalityId,
        nationality: inputs?.nationality,
        incomeTax: inputs?.incomeTax,
        marriageStatus: inputs?.maritalStatus?.value,
        gender: inputs?.mainGender,
        telePhone: inputs?.telePhone,
        address: inputs?.physicalAddress,
        address2: inputs?.address2,
        city: inputs?.city,
        province: inputs?.region,
        postalAddress: inputs?.postalAddress,
        postalCity: inputs?.postalCity,
        postalProvince: inputs?.postalProvince,
        workGPSCoordinate: inputs?.occupation,
        landlinePhone: inputs?.landlinePhone,
        landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace("+", "")}`,
        eMail: inputs?.eMail?.length
          ? inputs?.eMail
          : "clientservices@blil.co.bw",
        memberType: 1,
        relationship: 0,
      });
      handleNext(5);
    } catch (err) {
      setIsLoading(false);
    }
  };


  const handleBackTrack = () => {
   //trackUserEvents
   trackUserEvents("bw_complete_your_details_back_cta", {});
   handleBack();
 };

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const handleChangeBene = (evt, index) => {
    var arr = [...beneficiaries];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    if (evt.target.name === "dob") {
      arr[index] = {
        ...arr[index],
        "Trustee Phone Number Code":
          arr[index]?.["Trustee Phone Number Code"] ?? "+267",
      };
      setBeneficiaries(arr);
      return dispatch(
        updateCustomAttributes({
          type: "BENEFICIARIES",
          payload: arr,
        })
      );
    }
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  useEffect(() => {
    setSpouseExpand(accordionState?.spouse);
    setChildExpand(accordionState?.child);
    setExtendedExpand(accordionState?.extended);
    setParentExpand(accordionState?.parent);
    setBeneExpand(accordionState?.beneficiaries);
  }, []);

  useEffect(() => {
    dispatch(
      updateAccordion({
        policy: true,
        spouse: spouseExpand,
        child: childExpand,
        extended: extendedExpand,
        parent: parentExpand,
        beneficiaries: beneExpand,
      })
    );
  }, [spouseExpand, childExpand, extendedExpand, parentExpand, beneExpand]);

  const handleAdd = () => {
    setBeneficiaries([
      ...beneficiaries,
      {
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        percentage: "",
        dob: new Date(),
        postalAddress: "",
        gender: "",
        country: "",
        telephoneNumber: "",
        idNumber: "",
        "Trustee Phone Number Code": "+267",
      },
    ]);
  };

  const handleDelete = (i) => {
    if (beneficiaries?.length <= 1) return;
    var arr = [...beneficiaries];

    arr.splice(i, 1); // 2nd parameter means remove one item only

    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const [isTyped, setIsTyped] = useState(false);

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, []);

  const customAttribues = useSelector(
    (state) => state?.products?.customAttribues
  );

  const options = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((x) => x.code === RISKS.QUQ)
        ?.attributes?.find((y) => y.name === ATTRIBUTES.LIVES_COVERED)?.value
  );

  const bands = useSelector((state) => state?.products?.bands);
  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );

  const getExtended = () => {
    var arr = [];
    // extendedFamilies
    bands?.forEach((x) => {
      getRows(x?.value)?.map((y) => {
        arr.push({
          ...x,
          min: x?.name?.split(" ")?.[2]?.split("-")?.[0],
          max: x?.name?.split(" ")?.[2]?.split("-")?.[1],
        });
      });
    });
    return arr;
  };

  const calculateage = (date, i) => {
    var diffInYears = moment().diff(date, "years");
    if (diffInYears < 18) {
      return true;
    }
  };

  const calculateTrusteeMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  useEffect(() => {
    var divs = document.querySelectorAll("#accord-number");
    var divsArray = [].slice.call(divs);
    var displayNone = divsArray.filter(function (el) {
      return getComputedStyle(el).display === "none";
    });
    var displayShow = divsArray.filter(function (el) {
      return getComputedStyle(el).display !== "none";
    });
    var numberOfVisibleDivs = displayShow.length;
    getRows(+numberOfVisibleDivs).map((d, i) => (divs[i].innerHTML = i + 1));
  }, []);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await axios.get("https://restcountries.com/v3.1/all");
      var countArray = res.data
        .sort((a, b) =>
          a.name.common.toLowerCase() > b.name.common.toLowerCase() ? 1 : -1
        )
        .filter((f) => f.name.common.toLowerCase() !== "botswana")
        .map((d) => ({ label: d.name.common, value: d.name.common }));
      setCountries([
        {
          label: "Botswana",
          value: "Botswana",
        },
        ...countArray,
      ]);
    })();
  }, []);


  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <Accordion
            defaultExpanded={policyExpand ?? true}
            onChange={() => setPolicyExpand(!policyExpand)}
            expanded={policyExpand}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    1
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add policy holder details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <TextInput
                  id={"firstName"}
                  label={"First name"}
                  name={"firstName"}
                  value={inputs?.firstName}
                  errors={errors?.["firstName"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={true}
                />
                <TextInput
                  id={"lastName"}
                  label={"Last name or surname"}
                  name={"lastName"}
                  value={inputs?.lastName}
                  errors={errors?.["lastName"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={true}
                />

                <div style={{ marginTop: 10 }} />
                <TwoButtons
                  id={`mainGender`}
                  title={"Gender"}
                  isField={true}
                  half={true}
                  errors={errors?.[`mainGender`]}
                  state={inputs?.mainGender}
                  onClick={(e) =>
                    handleChangePolicy({
                      target: { name: "mainGender", value: e },
                    })
                  }
                  options={[
                    {
                      name: "Male",
                      value: "Male",
                    },
                    {
                      name: "Female",
                      value: "Female",
                    },
                  ]}
                  required={true}
                />
                <div className="form-group mt-3">
                  <label className="form-label">Nationality*</label>
                  <ReactSelect
                    id={`nationality`}
                    placeholder="Nationality"
                    value={
                      inputs?.nationality?.value
                        ? inputs?.nationality?.value
                        : {
                            label: inputs?.nationality,
                            value: inputs?.nationality,
                          }
                    }
                    options={countries}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "nationality", value: e.value },
                      });
                    }}
                  />
                  {errors?.["nationality"] && (
                    <span className="text-danger errors">
                      {errors?.["nationality"] && errors?.["nationality"][0]}
                    </span>
                  )}
                </div>
                <NumberInput
                  id={"nationalityId"}
                  label={"Identification / Passport number"}
                  name={"nationalityId"}
                  value={inputs?.nationalityId}
                  errors={errors?.["nationalityId"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <FileUpload
                  title={"Omang card front"}
                  id="omang_card_front"
                  name="omang_card_front"
                  onChange={handleFileUpload}
                  required={true}
                  value={files && files.omang_card_front}
                  errors={errors["omang_card_front"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <FileUpload
                  title={"Omang card back"}
                  id="omang_card_back"
                  name="omang_card_back"
                  onChange={handleFileUpload}
                  required={true}
                  value={files && files.omang_card_back}
                  errors={errors["omang_card_back"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <DateInput
                  id={"dob"}
                  label={"Date of birth"}
                  name={"dob"}
                  disabled={true}
                  minDate={calculateMinDate("parent")}
                  errors={errors?.["dob"]}
                  maxDate={calculateMaxDate("parent")}
                  value={inputs?.dob}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                  placeholder="dd/mm/yyyy"
                />
                <div className="form-group mt-3" id={"incomeTax"}>
                  <label className="form-label">Income tax number</label>
                  <input
                    id={"incomeTax"}
                    type="number"
                    name={"incomeTax"}
                    placeholder="If applicable"
                    className={"form-control"}
                    value={inputs?.incomeTax}
                    onChange={(e) => {
                      if (parseInt(e.target.value) !== NaN) {
                        if (e.target.value.toString().match(/^[0-9]*$/)) {
                          handleChangePolicy(e);
                        }
                      }
                    }}
                  />
                </div>
                <div className="form-group mt-3">
                  <label className="form-label">Marital status*</label>
                  <ReactSelect
                    id={`maritalStatus`}
                    placeholder="Select status"
                    value={inputs?.maritalStatus}
                    options={[
                      { value: "Married", label: "Married" },
                      { value: "Single", label: "Single" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "maritalStatus", value: e },
                      });
                    }}
                  />
                  {errors?.["maritalStatus"] && (
                    <span className="text-danger errors">
                      {errors?.["maritalStatus"] &&
                        errors?.["maritalStatus"][0]}
                    </span>
                  )}
                </div>
                <div className="row">
                  {width > 1024 ? (
                    <>
                      <div className="col-4">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+267"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-8">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          type={"number"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length <= 12) {
                              handleChangePolicy(e);
                            }
                          }}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+267"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-12">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          type={"number"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length <= 12) {
                              handleChangePolicy(e);
                            }
                          }}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  {width > 1024 ? (
                    <>
                      <div className="col-4">
                        <CountryInput
                          id={"landlinePhoneCode"}
                          label={"Country code"}
                          name={"landlinePhoneCode"}
                          errors={errors?.["landlinePhoneCode"]}
                          value={inputs?.landlinePhoneCode}
                          placeholder={"+267"}
                          onChange={(e) =>
                            handleChangePolicy(e, "landlinePhoneCode")
                          }
                          required={false}
                          country={true}
                        />
                      </div>
                      <div className="col-8">
                        <NumberInput
                          id={"landlinePhone"}
                          label={"Telephone number"}
                          name={"landlinePhone"}
                          type="number"
                          errors={errors?.["landlinePhone"]}
                          value={inputs?.landlinePhone}
                          onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*$/)) {
                              if (e?.target?.value?.length <= 12) {
                                handleChangePolicy(e);
                              }
                            }
                          }}
                          required={false}
                          mobile={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <CountryInput
                          id={"landlinePhoneCode"}
                          label={"Country code"}
                          name={"landlinePhoneCode"}
                          errors={errors?.["landlinePhoneCode"]}
                          value={inputs?.landlinePhoneCode}
                          placeholder={"+267"}
                          onChange={(e) =>
                            handleChangePolicy(e, "landlinePhoneCode")
                          }
                          required={false}
                          country={true}
                        />
                      </div>
                      <div className="col-12">
                        <NumberInput
                          id={"landlinePhone"}
                          label={"Telephone number"}
                          name={"landlinePhone"}
                          type="number"
                          errors={errors?.["landlinePhone"]}
                          value={inputs?.landlinePhone}
                          onChange={(e) => {
                            if (e.target.value.match(/^[0-9]*$/)) {
                              if (e?.target?.value?.length <= 12) {
                                handleChangePolicy(e);
                              }
                            }
                          }}
                          required={false}
                          mobile={true}
                        />
                      </div>
                    </>
                  )}
                </div>

                <TextInput
                  id={"eMail"}
                  label={"Email address"}
                  name={"eMail"}
                  errors={errors?.["eMail"]}
                  value={inputs?.eMail}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <div className="form-group mt-3">
                  <label className="primary-color f-700">
                    Physical address
                  </label>
                </div>
                <TextInput
                  id={"physicalAddress"}
                  label={"Address 1"}
                  errors={errors?.["physicalAddress"]}
                  name={"physicalAddress"}
                  value={inputs?.physicalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"address2"}
                  label={"Address 2"}
                  errors={errors?.["address2"]}
                  name={"address2"}
                  value={inputs?.address2}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"city"}
                  label={"City"}
                  errors={errors?.["city"]}
                  name={"city"}
                  value={inputs?.city}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <TextInput
                  id={"region"}
                  label={"Region"}
                  errors={errors?.["region"]}
                  name={"region"}
                  value={inputs?.region}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Postal address</label>
                </div>
                <TextInput
                  id={"postalAddress"}
                  label={"Address 1"}
                  errors={errors?.["postalAddress"]}
                  name={"postalAddress"}
                  placeholder="If not the same as physical address"
                  value={inputs?.postalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalCity"}
                  label={"City"}
                  errors={errors?.["postalCity"]}
                  name={"postalCity"}
                  value={inputs?.postalCity}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalProvince"}
                  label={"Region"}
                  errors={errors?.["postalProvince"]}
                  name={"postalProvince"}
                  value={inputs?.postalProvince}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["policy"] && (
            <span className="errors">{errors["policy"][0]}</span>
          )}

          <>
            {customAttributes?.spouse_cover === 0 && (
              <>
                <div style={{ height: "20px" }} />
                <Accordion
                  disabled={
                    options?.value === 1 ||
                    customAttributes?.spouse_cover === 1 ||
                    over60Option?.value === "Individual"
                  }
                  expanded={spouseExpand}
                  defaultExpanded={
                    customAttributes?.spouse_cover === 1
                      ? false
                      : expand.spouseExpand
                  }
                  onChange={() => setSpouseExpand(!spouseExpand)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          borderRadius: 99,
                          marginRight: 10,
                          backgroundColor: "white",
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="primary-color f-700"
                          id="accord-number"
                        >
                          2
                        </span>
                      </div>
                      <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                        Add spouse details here
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 mt-4">
                    <div
                      style={{
                        border: "1px solid #00000030",
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      {riskAttributes
                        .find((x) => x.code === "SPO")
                        ?.attributes?.filter(
                          (t) =>
                            t.name !== "Spouse Option ID or Passport" &&
                            t.name !== "Spouse ID Number or Passport no"
                        )
                        ?.map((y) => renderComponents(y, "spouse"))}
                    </div>
                  </AccordionDetails>
                </Accordion>
                {errors && errors["spouse"] && (
                  <span className="errors">{errors["spouse"][0]}</span>
                )}
              </>
            )}
            {customAttributes?.child_cover === 0 && (
              <>
                {" "}
                <div style={{ height: "20px" }} />
                <Accordion
                  disabled={
                    options?.value === 1 ||
                    customAttributes?.child_cover === 1 ||
                    over60Option?.value === "Individual"
                  }
                  defaultExpanded={
                    customAttributes?.child_cover === 1 ? false : childExpand
                  }
                  expanded={childExpand}
                  onChange={() => setChildExpand(!childExpand)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          borderRadius: 99,
                          marginRight: 10,
                          backgroundColor: "white",
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="primary-color f-700"
                          id="accord-number"
                        >
                          3
                        </span>
                      </div>
                      <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                        Add child details here
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 mt-4">
                    {getRows(customAttribues?.numberOfChildren).map((x, i) => (
                      <div
                        style={{
                          border: "1px solid #00000030",
                          marginTop: 20,
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        <p className="primary-color f-700">Child #{i + 1}</p>
                        {riskAttributes
                          .find((x) => x.code === "CHD")
                          ?.attributes?.filter(
                            (t) =>
                              t.name !== "Child Option ID or Passport" &&
                              t.name !== "Child ID Number or Passport no"
                          )
                          ?.map((y) => renderComponents(y, "child", i))}
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
                {errors && errors["child"] && (
                  <span className="errors">{errors["child"][0]}</span>
                )}
              </>
            )}
            <div style={{ height: "20px" }} />
            {getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES" && (
              <>
                <Accordion
                  disabled={
                    options?.value === 1 ||
                    getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "NO" ||
                    over60Option?.value === "Individual"
                  }
                  expanded={
                    getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "NO"
                      ? false
                      : extendedExpand
                  }
                  defaultExpanded={
                    getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES"
                      ? false
                      : extendedExpand
                  }
                  onChange={() => setExtendedExpand(!extendedExpand)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel1a-header"
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          borderRadius: 99,
                          marginRight: 10,
                          backgroundColor: "white",
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="primary-color f-700"
                          id="accord-number"
                        >
                          4
                        </span>
                      </div>
                      <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                        Add extended family details here
                      </p>
                    </div>
                  </AccordionSummary>
                  {expand?.extended && (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#3C409620",
                        borderRadius: 10,
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingLeft: "20px",
                        alignItems: "center",
                      }}
                    >
                      <p className="primary-color f-700 m-0">
                        {" "}
                        P {
                          getAttributes("Extended Family Cover Amount")?.value
                        }{" "}
                        : Extended Family Cover
                      </p>
                    </div>
                  )}
                  <AccordionDetails className="p-0 mt-4">
                    {getExtended()?.map((x, i) => (
                      <div
                        style={{
                          border: "1px solid #00000030",
                          marginTop: 20,
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        <p className="primary-color f-700">
                          {/* Extended Family #{i + 1} {`(${x?.name})`} */}
                          Extended Family #{i + 1}
                        </p>
                        {riskAttributes
                          .find((x) => x.code === "EXF")
                          ?.attributes?.filter(
                            (t) =>
                              t.name !==
                                "Extended Family Option ID or Passport" &&
                              t.name !==
                                "Extended Family ID Number or Passport no"
                          )
                          ?.map((y) => renderComponents(y, "extended", i, x))}
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
                {errors && errors["extended"] && (
                  <span className="errors">{errors["extended"][0]}</span>
                )}
                <div style={{ height: "20px" }} />
              </>
            )}
          </>

          {dateOfBirth &&
          calculateAge60(dateOfBirth) < 60 &&
          getRows(totalParents())?.length ? (
            <Accordion
              disabled={
                options?.value === 1 ||
                getAttributes(ATTRIBUTES.PARENTS)?.value === 1 ||
                (dateOfBirth && calculateAge60(dateOfBirth) > 60)
              }
              defaultExpanded={parentExpand}
              expanded={parentExpand}
              onChange={() => setParentExpand(!parentExpand)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel1a-header"
              >
                <div className="d-flex flex-row">
                  <div
                    style={{
                      borderRadius: 99,
                      marginRight: 10,
                      backgroundColor: "white",
                      width: 30,
                      height: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="primary-color f-700" id="accord-number">
                      5
                    </span>
                  </div>
                  <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                    Add parent details here
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails className="p-0 mt-4">
                {getRows(totalParents()).map((x, i) => (
                  <div
                    style={{
                      border: "1px solid #00000030",
                      marginTop: 20,
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    <p className="primary-color f-700">Parent #{i + 1}</p>
                    {riskAttributes
                      .find((x) => x.code === "PAR")
                      ?.attributes?.filter(
                        (t) =>
                          t.name !== "Parent Option ID or Passport" &&
                          t.name !== "Parent ID Number or Passport no"
                      )
                      ?.map((y) => renderComponents(y, "parent", i))}
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : (
            <></>
          )}
          {errors && errors["parent"] && (
            <span className="errors">{errors["parent"][0]}</span>
          )}
          <div style={{ height: "20px" }} />
          <Accordion
            defaultExpanded={beneExpand}
            expanded={beneExpand}
            onChange={() => setBeneExpand(!beneExpand)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    {dateOfBirth && calculateAge60(dateOfBirth) < 60
                      ? "6"
                      : "5"}
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add beneficiaries details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {getRows(beneCount)?.map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Beneficiary #{i + 1}</p>
                  <TextInput
                    id={`First Name ${i + 1}`}
                    label={"First name"}
                    name={"firstName"}
                    placeholder={""}
                    value={beneficiaries[i]?.["firstName"]}
                    errors={errors?.[`Beneficiary First Name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                    maxLength={30}
                  />
                  <TextInput
                    id={`Last Name ${i + 1}`}
                    label={"Last name"}
                    name={"lastName"}
                    placeholder={""}
                    value={beneficiaries[i]?.["lastName"]}
                    errors={errors[`Beneficiary Last Name ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                    maxLength={30}
                  />
                  <DateInput
                    id={`dob ${i + 1}`}
                    label={"Date of birth"}
                    name={"dob"}
                    minDate={calculateBeneMinDate()}
                    errors={errors[`Beneficiary Date of Birth ${i + 1}`]}
                    maxDate={calculateBeneMaxDate()}
                    value={beneficiaries[i]?.["dob"] ?? ""}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                    placeholder="dd/mm/yyyy"
                  />
                  <div style={{ marginTop: 20 }} />
                  <TwoButtons
                    id={`Gender ${i + 1}`}
                    title={"Gender"}
                    isField={true}
                    half={true}
                    required={true}
                    errors={errors?.[`Gender ${i + 1}`]}
                    state={beneficiaries[i]?.["gender"]}
                    onClick={(e) =>
                      handleChangeBene(
                        { target: { name: "gender", value: e } },
                        i
                      )
                    }
                    options={[
                      {
                        name: "Male",
                        value: "Male",
                      },
                      {
                        name: "Female",
                        value: "Female",
                      },
                    ]}
                  />

                  <div className="row">
                    {width > 1024 ? (
                      <>
                        <div className="col-4">
                          <CountryInput
                            id={`Phone Number Code ${i + 1}`}
                            label={"Country code*"}
                            name={"Phone Number Code"}
                            errors={errors?.[`Phone Number Code ${i + 1}`]}
                            value={beneficiaries[i]?.["Phone Number Code"]}
                            placeholder={"+267"}
                            onChange={(e) =>
                              handleChangeBene(
                                {
                                  target: {
                                    name: "Phone Number Code",
                                    value: e,
                                  },
                                },
                                i
                              )
                            }
                            required={false}
                            country={true}
                          />
                        </div>
                        <div className="col-8">
                          <NumberInput
                            id={`Phone Number ${i + 1}`}
                            label={"Mobile number"}
                            name={"number"}
                            type={"number"}
                            errors={errors[`Phone Number ${i + 1}`]}
                            value={beneficiaries[i]?.["number"]}
                            onChange={(e) => {
                              if (e?.target?.value?.length <= 12) {
                                handleChangeBene(e, i);
                              }
                            }}
                            required={true}
                            mobile={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12">
                          <CountryInput
                            id={`Phone Number Code ${i + 1}`}
                            label={"Country code*"}
                            name={"Phone Number Code"}
                            errors={errors?.[`Phone Number Code ${i + 1}`]}
                            value={beneficiaries[i]?.["Phone Number Code"]}
                            placeholder={"+267"}
                            onChange={(e) =>
                              handleChangeBene(
                                {
                                  target: {
                                    name: "Phone Number Code",
                                    value: e,
                                  },
                                },
                                i
                              )
                            }
                            required={false}
                            country={true}
                          />
                        </div>
                        <div className="col-12">
                          <NumberInput
                            id={`Phone Number ${i + 1}`}
                            label={"Mobile number"}
                            name={"number"}
                            type={"number"}
                            errors={errors[`Phone Number ${i + 1}`]}
                            value={beneficiaries[i]?.["number"]}
                            onChange={(e) => {
                              if (e?.target?.value?.length <= 12) {
                                handleChangeBene(e, i);
                              }
                            }}
                            required={true}
                            mobile={true}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    {width > 1024 ? (
                      <>
                        <div className="col-4">
                          <CountryInput
                            id={`Telephone Number Code ${i + 1}`}
                            label={"Country code"}
                            name={"Telephone Number Code"}
                            errors={errors?.[`Telephone Number Code ${i + 1}`]}
                            value={beneficiaries[i]?.["Telephone Number Code"]}
                            placeholder={"+267"}
                            onChange={(e) =>
                              handleChangeBene(
                                {
                                  target: {
                                    name: "Telephone Number Code",
                                    value: e,
                                  },
                                },
                                i
                              )
                            }
                            required={false}
                            country={true}
                          />
                        </div>
                        <div className="col-8">
                          <NumberInput
                            id={`Telephone Number ${i + 1}`}
                            label={"Telephone number"}
                            type="number"
                            name={"telephoneNumber"}
                            value={beneficiaries[i]?.["telephoneNumber"]}
                            errors={errors[`Telephone number ${i + 1}`]}
                            onChange={(e) => {
                              if (e?.target?.value?.length <= 12) {
                                handleChangeBene(e, i);
                              }
                            }}
                            required={false}
                            mobile={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-12">
                          <CountryInput
                            id={`Telephone Number Code ${i + 1}`}
                            label={"Country code"}
                            name={"Telephone Number Code"}
                            errors={errors?.[`Telephone Number Code ${i + 1}`]}
                            value={beneficiaries[i]?.["Telephone Number Code"]}
                            placeholder={"+267"}
                            onChange={(e) =>
                              handleChangeBene(
                                {
                                  target: {
                                    name: "Telephone Number Code",
                                    value: e,
                                  },
                                },
                                i
                              )
                            }
                            required={false}
                            country={true}
                          />
                        </div>
                        <div className="col-12">
                          <NumberInput
                            id={`Telephone Number ${i + 1}`}
                            label={"Telephone number"}
                            name={"telephoneNumber"}
                            type="number"
                            value={beneficiaries[i]?.["telephoneNumber"]}
                            errors={errors[`Telephone number ${i + 1}`]}
                            onChange={(e) => {
                              if (e?.target?.value?.length <= 12) {
                                handleChangeBene(e, i);
                              }
                            }}
                            required={false}
                            mobile={true}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <TextInput
                    id={`Email ${i + 1}`}
                    label={"Email address"}
                    name={"email"}
                    value={beneficiaries[i]?.["email"]}
                    errors={errors[`Email ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={false}
                  />

                  <div className="form-group mt-3">
                    <label className="form-label">Percentage of benefit*</label>
                    <ReactSelect
                      id={`Percentage ${i + 1}`}
                      placeholder="0"
                      defaultValue={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      value={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      options={
                        isTyped
                          ? range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            }))
                          : percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            }))
                      }
                      onInputChange={(e) => {
                        if (e > 0) {
                          setIsTyped(true);
                        } else {
                          setIsTyped(false);
                        }
                      }}
                      onChange={(e) => {
                        handleChangeBene(
                          { target: { name: "percentage", value: e.value } },
                          i
                        );
                      }}
                      isSearchable={true}
                    />
                  </div>
                  {(
                    errors[`Percentage ${i + 1}`] ||
                    errors[`beneficiariesSplit`]
                  )?.length && (
                    <span
                      className="text-danger errors"
                      style={{ marginLeft: 0 }}
                    >
                      {errors[`Percentage ${i + 1}`] ||
                        (errors[`beneficiariesSplit`] &&
                          errors[`Percentage ${i + 1}`]) ||
                        errors[`beneficiariesSplit`][0]}
                    </span>
                  )}
                  {beneficiaries[i]?.["dob"] &&
                    calculateage(beneficiaries[i]?.["dob"], i) && (
                      <div
                        style={{
                          border: "1px solid #00000030",
                          marginTop: 20,
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        <p className="primary-color f-700">Trustee</p>
                        <TextInput
                          id={`Trustee First Name ${i + 1}`}
                          label={"First name"}
                          name={"trusteeName"}
                          value={beneficiaries[i]?.["trusteeName"]}
                          errors={errors[`Trustee First Name ${i + 1}`]}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                          maxLength={30}
                        />
                        <TextInput
                          id={`Trustee Last Name ${i + 1}`}
                          label={"Last name"}
                          errors={errors[`Trustee Last Name ${i + 1}`]}
                          name={"trusteeLastName"}
                          value={beneficiaries[i]?.["trusteeLastName"]}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                          maxLength={30}
                        />
                        <div className="row">
                          {width > 1024 ? (
                            <>
                              <div className="col-4">
                                <CountryInput
                                  id={`Trustee Phone Number Code ${i + 1}`}
                                  label={"Country code*"}
                                  width={"110%"}
                                  name={"Trustee Phone Number Code"}
                                  errors={
                                    errors?.[
                                      `Trustee Phone Number Code ${i + 1}`
                                    ]
                                  }
                                  value={
                                    beneficiaries[i]?.[
                                      "Trustee Phone Number Code"
                                    ]
                                  }
                                  placeholder={"+267"}
                                  onChange={(e) =>
                                    handleChangeBene(
                                      {
                                        target: {
                                          name: "Trustee Phone Number Code",
                                          value: e,
                                        },
                                      },
                                      i
                                    )
                                  }
                                  required={false}
                                  country={true}
                                />
                              </div>
                              <div className="col-8">
                                <NumberInput
                                  id={`Trustee Phone Number ${i + 1}`}
                                  label={"Mobile number"}
                                  name={"trusteeNumber"}
                                  type={"number"}
                                  value={beneficiaries[i]?.["trusteeNumber"]}
                                  errors={
                                    errors[`Trustee Phone Number ${i + 1}`]
                                  }
                                  onChange={(e) => {
                                    if (e?.target?.value?.length <= 12) {
                                      handleChangeBene(e, i);
                                    }
                                  }}
                                  required={true}
                                  mobile={true}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-11">
                                <CountryInput
                                  id={`Trustee Phone Number Code ${i + 1}`}
                                  label={"Country code*"}
                                  width={"100%"}
                                  name={"Trustee Phone Number Code"}
                                  errors={
                                    errors?.[
                                      `Trustee Phone Number Code ${i + 1}`
                                    ]
                                  }
                                  value={
                                    beneficiaries[i]?.[
                                      "Trustee Phone Number Code"
                                    ]
                                  }
                                  placeholder={"+267"}
                                  onChange={(e) =>
                                    handleChangeBene(
                                      {
                                        target: {
                                          name: "Trustee Phone Number Code",
                                          value: e,
                                        },
                                      },
                                      i
                                    )
                                  }
                                  required={false}
                                  country={true}
                                />
                              </div>
                              <div className="col-12">
                                <NumberInput
                                  id={`Trustee Phone Number ${i + 1}`}
                                  label={"Mobile number"}
                                  name={"trusteeNumber"}
                                  type={"number"}
                                  value={beneficiaries[i]?.["trusteeNumber"]}
                                  errors={
                                    errors[`Trustee Phone Number ${i + 1}`]
                                  }
                                  onChange={(e) => {
                                    if (e?.target?.value?.length <= 12) {
                                      handleChangeBene(e, i);
                                    }
                                  }}
                                  required={true}
                                  mobile={true}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <DateInput
                          id={`Trustee dob ${i + 1}`}
                          label={"Date of birth"}
                          name={"trusteeDob"}
                          minDate={calculateBeneMinDate()}
                          errors={errors[`Trustee dob ${i + 1}`]}
                          maxDate={calculateTrusteeMaxDate()}
                          value={beneficiaries[i]?.["trusteeDob"] ?? ""}
                          onChange={(e) => handleChangeBene(e, i)}
                          required={true}
                          placeholder="dd/mm/yyyy"
                        />
                      </div>
                    )}
                  <div className="d-flex justify-content-end w-100 mt-4">
                    {beneCount > 1 && (
                      <button
                        id="delete_bene"
                        className="delete-button"
                        onClick={() => {
                          if (beneCount > 1) {
                            var arr = [...beneficiaries];
                            arr.splice(i, 1);
                            setBeneficiaries(arr);
                            dispatch(
                              updateCustomAttributes({
                                type: "BENEFICIARIES",
                                payload: arr,
                              })
                            );
                            setBeneCount(beneCount - 1);
                          }
                        }}
                      >
                        Delete
                      </button>
                    )}
                    {beneCount < 10 && getRows(beneCount)?.length - 1 === i && (
                      <button
                        id="add_bene"
                        className="add-button"
                        onClick={() => {
                          var arr = [...beneficiaries];
                          arr.push({
                            "Phone Number Code": "+267",
                            "Telephone Number Code": "+267",
                          });
                          setBeneficiaries(arr);
                          dispatch(
                            updateCustomAttributes({
                              type: "BENEFICIARIES",
                              payload: arr,
                            })
                          );
                          setBeneCount(beneCount + 1);
                        }}
                      >
                        Add Beneficiaries
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">
            P
            {premiumAmount
              ?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        backId={"step4_back"}
        nextId={"step4_next"}
        isBackDisabled={isLoading}
        loading={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z /-]+$/,
      message: "^Only alphabetic characters, hyphen and space are allowed",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z /-]+$/,
      message: "^Only alphabetic characters, hyphen and space are allowed",
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
      message: "^Nationality ID can't be blank",
    },
    length: {
      maximum: 9,
      message: "^Nationality ID is too long (maximum is 9 characters)",
    },
    format: {
      pattern: /^[\w-_.]*$/,
      message: "^ Only alphabets & numbers are allowded",
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: "^ Mobile number can't be blank",
    },
    format: {
      pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
      message: "^ Invalid mobile number",
    },
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
      message: "^Country code can't be blank",
    },
    length: {
      maximum: 4,
    },
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z0-9]+$/,
      message:
        "^ Only alphabetic characters, number, hyphen and space are allowed",
    },
  },
  city: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  region: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
    },
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step4;
