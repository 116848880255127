// default imports for react project
import React, { useState, useEffect } from 'react';

// import default styles from react-bootstrap package
import { Col, Row } from 'react-bootstrap';

// Import images from assets folder

//   import custom component
import BodyButton from "../Buttons/BodyButton";
import { box , liberty } from "../../assets/images";
import { Navigate, useNavigate, useLocation} from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getProductRisks, getProductRisksAttributes,updatedAttributes, getProductTypeDetails, getProductAttachments, updateCustomAttributes } from '../../store/actions/products';
import { getAgentOnboarding, login } from '../../store/actions/auth';
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import moment from 'moment';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useCallback } from 'react';
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";


// React functional component starts here..
function Card2({ width }) {
  const location = useLocation();
  useEffect(() => {
  }, [location]);
  const history = useNavigate();
  const [dob, setDOB] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const reduxState = useSelector((state) => state);
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const state = useSelector((state) => state?.products?.riskAttributes);
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const customAttributes = useSelector((state) => state?.products?.customAttributes);
  const riskAttributes = useSelector((state) => state?.products?.riskAttributes?.find((x) => x.code === 'QUQ')?.attributes);
  const [errors, setErrors] = useState({});
  const countDown = useSelector((state) => state?.products?.countDown.count);
  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  const [,updateState] = useState()
   const forceUpdate = useCallback(()=>updateState({}))


   useEffect(() => {
    dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
  },[])

 
  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])

  const handleSubmit = async () => {
    try{
      if (!dob) {
        return setErrors({ dob: ["Date of birth is required"] });
      }
      setIsLoading(true);
      setIsCounter(true);
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }

      await dispatch(login());
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductAttachments());
      await dispatch(getProductTypeDetails());
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          await dispatch(getProductRisksAttributes(risk.instanceId));
        });
      };
      await act();
      history("/forms");
      setIsLoading(false);
      setIsCounter(false);
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            dob,
          },
        })
      );

      //trackUserEvents
        trackUserEvents('bw_care_insurnace_get_a_quote_cta', {});
    }
    catch(err){
      console.log(err);
    }
  }



  const calculateMinDate = (type) => {
    return moment().subtract(18, 'years')?.format('YYYY-MM-DD');
  };

  

  return (
    <div>
    <Col lg xs={12}>
      {/* <Row className="m-0 pb-5 card__homepage"> */}
        <Row className="m-0 card__homepage">
        <div
          style={{
            backgroundColor: '#E5E5F5',
            padding: width < 430 ? '0px' : '',
          }}
        >
          <Row className="m-4">
            <Col lg={10}>
              <h3 style={{ fontSize: '26pt', color: '#2F2E80' }}>
                It's never been easier to get insurance cover.{' '}
              </h3>
              <h6 style={{ fontSize: '13pt', color: '#2F2E80' }}>
                if something was to happen to you, you can make sure your family is protected
              </h6>
            </Col>
            <Col lg={2}>
            <Row>
              <h6
                  style={{
                    color: "#56616C",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center", 
                    marginTop:"8px"
                  }}
                >Policy underwritten by</h6>
            </Row>
            <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={liberty}
                    style={{
                      marginLeft: 36.2,
                      width: "95px",
                    }} 
                    alt="Liberty Logo"
                  />
                </div>
            </Row>
            </Col>
          </Row>
          <Row>
            <hr
              style={{
                color: 'white',
                height: 2,
                width: '97%',
                margin: 'auto',
              }}
            />
          </Row>
          <Row className="secondary-container p-3 ">
          <Row className="secondary-container p-3 ">
            <Col lg={7}>
              <Row>
                  <Col lg={3} style={{textAlign:"right"}}>
                    <img src={box} style={{
                        width:"60%",
                  }} alt="box" />
                  </Col>
                  <Col lg={9}>
                    <h4
                    style={{
                      fontSize: '20px',
                      color: '#2F2E80',
                      fontWeight: 'bold',
                      marginBottom:"15px"
                    }}
                  >
                  Mosako Family Funeral
                  </h4>
                  <span style={{color: "#2F2E80", fontWeight: 'bold',}}>Each option covers:</span>
                      <ul>
                        {data2.map((d) => (
                          <li style={{ color: "#2F2E80", paddingRight: "13%", marginTop:"8px" }}>
                          <span>{d}</span>
                          </li>
                        ))}
                      </ul>
                  </Col>
                </Row>    
            </Col> 

            <Col lg={5} className="d-flex justify-content-center align-items-center">  
                <div className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"  style={{height:"100%"}}>
                  <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                   Mosako Family Funeral
                </h4> 
                  </Row> 
                  <Row>
                <h4
                  style={{
                    fontSize: '11pt',
                    textAlign: 'center',
                    color: 'white',
                    fontWeight:"bold",
                  }}
                >
                  Cover Option for under 60 and over 60's
                  <br />
                </h4>
              </Row>
              <Row>
              <div className='homepage_dob_container'>
                    <p className="text-white f-700 m-0" style={{fontSize:"11pt"}}>When were you born?*</p>
                    <input 
                    id="dob-id"
                    type="text"
                    max={calculateMinDate()}
                    style={{margin: 5,width: '250px',height:"34px"}}
                     onChange={(e) => {
                      dispatch(updateCustomAttributes({
                        type: 'CUSTOM_ATTRIBUTES',
                        payload: {
                          ...customAttributes,
                          dateOfBirth: e.target.value
                        }
                      }))
                       setDOB(e.target.value);
                      
                    }} 
                    placeholder="dd/mm/yyyy" 
                    onFocus={(e) => e.target.type = 'date'} 
                    onBlur={(e) => e.target.type = 'date'} 
                    onKeyDown={(e) => {e.preventDefault()}}
                    value={dob} 
                    min={'1948-01-01'}
                    />
                    {errors?.dob ? <span className='errors' style={{color:'#fff'}}>{errors?.dob?.[0]}</span> : <></>}
                    
                    <button id="home_quote_button1" onClick={handleSubmit}>{isLoading ? 'Quoting...' : 'Get a quote'}</button>                   
                  </div>
              </Row>
              <Row
              className="justify-content-center"
              style={{
                fontSize: '11pt',
                color: 'white',
                textAlign: 'center',
                marginTop: '8px',
                fontWeight: "bold",
                marginBottom: "25px",
              }}
            >
              From: P 55 / Monthly
            </Row>
            <hr
                  style={{
                    color: "white",
                    height: 1,
                    width: "97%",
                    margin: "auto",
                    marginTop:"20px"
                  }}
                />
                <Row
                    className="justify-content-center"
                    style={{
                      fontSize: "8pt",
                      color: "white",
                      textAlign: "center",
                      marginBottom: "5px",
                      padding:"14px",
                      fontWeight:"bold"
                    }}
                  >
                    By opting to buy insurance, Letshego will share the required
                    information with the insurer to facilitate your policy purchase.
                </Row>
                <Row>
                  <h6
                      style={{
                        color: "white",
                        fontSize: "8pt",
                        fontWeight: "bold",
                        textAlign: "center", 
                        marginTop:"5px"
                      }}
                    >Policy underwritten by</h6>
              </Row>   
              <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={liberty}
                    style={{
                      width: "95px",
                    }} 
                    alt="liberty"
                  />
                </div>
            </Row>
                </div>
            </Col>    
          </Row>
          </Row>    
        </div>
      </Row>
    </Col>
    {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{height: 100, width: 100}}>
            {/* {renderInitialLoader()} */}
            <CircularProgressbar styles={buildStyles({textColor: '#fbcf2c', pathColor:'#fbcf2c'})} value={countDown} text={`${countDown}%`} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

const data2 = [
  'Policyholder, one spouse and a maximum of 6 children with a choice to add parents/parents in law or extended family members.',
  'All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.',
];
