import {
  BANK_BRANCH_GUID,
  ENV,
  ENV_TYPE,
  MIDDLEWARE_URL,
  OCP_APIM_SUBSCRIPTION_KEY,
  PRODUCT_INSTANCE_ID,
  SITE_URL,
} from "./api-constants";

// <-------- Constants ------->

// Define the ENV TYPE in ENV_VAR
const ENV_VAR = ENV_TYPE[process.env.REACT_APP_ENV_TYPE];
const CONSTANTS = {
  BASEURL: ENV[ENV_VAR],
  ENV: ENV_VAR,
  OCP_APIM_SUBSCRIPTION_KEY: OCP_APIM_SUBSCRIPTION_KEY[ENV_VAR],
  PRODUCT_INSTANCE_ID: PRODUCT_INSTANCE_ID[ENV_VAR],
  BANK_BRANCH_GUID: BANK_BRANCH_GUID[ENV_VAR],
  MIDDLEWARE_URL: MIDDLEWARE_URL[ENV_VAR],
  CURRENCY_CODE: "ZAR",
  SITE_URL: SITE_URL[ENV_VAR],
};
export default CONSTANTS;

export const PRODUCTION_CONSTANTS = {
  REFERER_BASE_URL_PROD: "https://policy-cache-itp2-prod-za.azurewebsites.net",
  COMPANY_DETAILS: "https://organisation-itp2-prod-za.azurewebsites.net",
  PRODUCT_RISKS: "https://product-itp2-prod-za.azurewebsites.net",
  BANK_LOOKUPS: "https://maintaindata-itp2-prod-za.azurewebsites.net",
  POLICY: "https://policy-itp2-prod-za.azurewebsites.net",
};

// <-------- Common Messages ------->
export const COMMONMESSAGES = {};

export const ATTRIBUTES = {
  LIVES_COVERED: "Lives Covered",
  COVER_OPTIONS: "Cover Option",
  COVER_AMOUNT: "Cover Amount",
  CHILD_COVER: "Child Cover",
  PARENTS: "Parents",
  PARENT_IN_LAW: "Parents-in-Law",
  NUMBER_OF_PARENTS_IN_LAW: "Number of Parents-in-Law",
  EXTENDED_FAMILY: "Extended Family",
  IMMEDIATE_FAMILY:"Immediate Family",
  NUMBER_OF_EXTENDED_FAMILY: "Number of Extended Family Members",
  SPOUSE_COVER: "Spouse Cover",
  PROTECTION_REQUIREMENT: "Protection Requirement",
  NUMBER_OF_CHILDREN: "Number of Children",
  PARENTAL_COVER: "Parents",
  NUMBER_OF_PARENTS: "Number of Parents",
  PARENT_55: "Parents < 55 yrs",
  PARENT_65: "Parents 55 - 65 yrs",
  PARENT_80: "Parents 65 - 80 yrs",
  LIBERTY_POLICIES: "Main Member Other Liberty Policies",
  OCCUPATION: "Main member occupation",
  OCCUPATION_INDUSTRY: "Main Member Occupation Industry",
  COUNTRY_BIRTH: "Main Member Country of Birth",
  CITIZENSHIP: "Main Member Citizenship",
  BEN_FIRSTNAME: "Beneficiary First Name",
  BEN_LASTNAME: "Beneficiary Last Name",
  BEN_ID_NUM: "Beneficiary ID Number",
  BEN_DOB: "Beneficiary Date of Birth",
  BEN_COUNTRY: "Beneficiary Country of Birth",
  CHILD_RELATIONSHIP: "Child Relationship to Main Member",
  CHILD_GENDER: "Child Gender",
  DATE_OF_BIRTH: 'Date of Birth',
  COVER_AMOUNT_60_PLUS: 'Cover Amount 60 Plus'
};

export const RISKS = {
  QUQ: "QUQ",
  PLA: "PLA",
  PAL: "PAL",
  CLA: "CLA",
  SLA: "SLA",

  UQQ: "UQQ",
};


export const CONFIG = {
  development: "http://localhost:3001",
  uat: "https://app.uat.letshego.com",
  production: "https://app.uat.letshego.com",
  // production: "https://app.production.com",
};