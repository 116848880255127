/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  AboutImage,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
// import brochure from "../../assets/documents/Our-Business.pdf";
import brochure from "../../assets/documents/About.pdf";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function About({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            About{" "}
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            About Letshego Africa
          </p>
        </Row>
        <Row className="align-items-start">
          <Col md={6}>
            <p style={{ color: "#2f2e80" }}>
              Letshego Africa (Letshego Group”) is a truly African multinational
              organisation, headquartered and listed in Botswana and focused on
              delivering inclusive finance solutions to underserved populations
              across its 11 sub-Saharan Africa footprint.
            </p>
            <p style={{ color: "#2f2e80" }}>
              With a staff complement of over 3,000 – including both direct and
              indirect sales agents – Letshego supports over five million
              customers across Africa.
            </p>
            <p style={{ color: "#2f2e80" }}>
              Letshego is synonymous with leveraging innovation and technology
              to improve the lives of individuals who have limited access to
              traditional financial services.
            </p>
            <p style={{ color: "#2f2e80" }}>
              In September 2020 Letshego launched its five-year Transformational
              Strategy, accelerating the Group towards its vision to be a
              world-class retail financial services organisation, improving the
              lives of mass and middle-market individuals as well as small
              business owners and micro and small entrepreneurs.
            </p>
            <p style={{ color: "#2f2e80" }}>
              This year, Letshego celebrates 25 years of supporting regional
              communities.
            </p>
          </Col>
          <Col md={6} className="d-flex align-items-start">
            <div style={{ color: "#2f2e80" }}>
              <img
                className="main__img"
                src={AboutImage}
                alt="main"
                style={{ width: "100%", paddingTop: "0%", height: "auto" }}
              />
            </div>
          </Col>
        </Row>
        <div style={{ height: "220px" }}></div>
      </Container>
    </div>
  );
}

export default About;
