import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  executeCalculator,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { radioChecked, radioUnchecked } from "../../../assets/images";
import moment from "moment";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step1 = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);

  const history = useNavigate();

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getConstrains = () => {
    var val = {};
    if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
      val = {
        [getAttributes("Cover Option")?.name]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
      return val;
    } else {
      val = {
        [getAttributes("Cover Option 60 Plus")?.name]: {
          presence: {
            allowEmpty: false,
          },
        },
        [getAttributes("Cover Amount 60 Plus")?.name]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
      return val;
    }
  };

  const reduxState = useSelector((state) => state);
  

  const handleSubmit = async () => {
    try {
      var values = {};
      if (calculateAge60(dateOfBirth) < 60) {
        values = {
          [getAttributes("Cover Option")?.name]:
            getAttributes("Cover Option")?.value,
        };
      } else {
        values = {
          [getAttributes("Cover Option 60 Plus")?.name]: getAttributes(
            "Cover Option 60 Plus"
          )?.value,
          [getAttributes("Cover Amount 60 Plus")?.name]: getAttributes(
            "Cover Amount 60 Plus"
          )?.value,
        };
      }

      const errors = validate(values, getConstrains());

      if (errors || errors !== undefined) {
        return setErrors(errors);
      }
      setIsLoading(true);
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value: y.name === "Date of Birth" ? dateOfBirth : y.value,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
        })
      );

      if (dateOfBirth && calculateAge60(dateOfBirth) > 60) {
        if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
          return handleNext(2);
        }
      }
      //trackUserEvents
      trackUserEvents("bw_care_insurance_select_your_cover_cta", {coverDetails: selectedOption});
      handleNext();
      return setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    history("/");
    //trackUserEvents
    trackUserEvents("bw_care_insurance_select_your_cover_back_cta", {});
  };


  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (getAttributes("Cover Option")?.value) {
      setSelectedOption(getAttributes("Cover Option")?.value);
    }
  }, []);

  const under60Options = [
    "P15,000 cover for P55",
    "P15,000 cover for P66",
    "P15,000 cover for P77",
  ];

  useEffect(() => {
    if (dateOfBirth && calculateAge60(dateOfBirth) > 60) {
      if (getAttributes("Cover Amount 60 Plus")?.value) {
        setSelectedOption(getAttributes("Cover Amount 60 Plus")?.value);
      }
    }
  }, []);

  return state?.length ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      {/* {calculateAge60(dateOfBirth) < 60 ? ( */}
      {dateOfBirth && calculateAge60(dateOfBirth) < 60 ? (
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
          <div style={{ maxWidth: "460px", width: "100%" }}>
            {width > 1024 ? (
              <p className="primary-color f-700">
                Welcome to Mosako under 60 funeral plan
              </p>
            ) : (
              <p className="primary-color f-700 text-center">
                Welcome to Mosako under 60 funeral plan
              </p>
            )}
            <div className={style.coverOptions}>
              <p className="primary-color f-700">Choose your cover option</p>
              {JSON.parse(getAttributes("Cover Option")?.settings)?.items?.map(
                (item, index) => (
                  <div
                    className={
                      selectedOption === item
                        ? style.optionsActive
                        : style.options
                    }
                    onClick={() => {
                      setSelectedOption(item);
                      var arr = [...riskAttributes];
                      const index = riskAttributes?.findIndex(
                        (x) => x.name === "Cover Option"
                      );
                      arr[index] = { ...arr[index], value: item };
                      dispatch(updatedAttributes(arr, "QUQ"));
                    }}
                  >
                    <div>
                      <img
                        src={
                          selectedOption === item
                            ? radioChecked
                            : radioUnchecked
                        }
                      />
                    </div>
                    <div className="d-flex flex-column">
                      {/* {setCoverOption(under60Options[index])} */}
                      <p className="primary-color f-700 mb-0">
                        {under60Options[index]}
                      </p>
                      <span
                        className={`${
                          selectedOption === item
                            ? "text-white"
                            : "primary-color"
                        }  f-500 ml-2`}
                        style={{ marginLeft: "20px" }}
                      >
                        {item}
                      </span>
                    </div>
                  </div>
                )
              )}
              {errors?.["Cover Option"] ? (
                <span className="errors">{errors?.["Cover Option"]?.[0]}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center flex-column mt-5">
          <div style={{ maxWidth: "460px", width: "100%" }}>
            <p className="primary-color f-700">
              Welcome to Mosako over 60 funeral plan
            </p>
            <TwoButtons
              id="Cover Options"
              half={false}
              title={"Who do you want to cover?"}
              state={getAttributes("Cover Option 60 Plus")?.value}
              errors={errors?.["Cover Option 60 Plus"]}
              onClick={(value) => {
                var arr = [...riskAttributes];
                const index = riskAttributes?.findIndex(
                  (x) => x.name === "Cover Option 60 Plus"
                );
                arr[index] = { ...arr[index], value: value };
                dispatch(updatedAttributes(arr, "QUQ"));
              }}
              options={[
                {
                  name: "Just me",
                  value: "Individual",
                },
                {
                  name: "Me & family",
                  value: "Family",
                },
              ]}
            />
            <div style={{ height: 10 }} />
            <div className={style.coverOptions}>
              <p className="primary-color f-700">
                Choose your cover amount option?
              </p>
              {JSON.parse(
                getAttributes(ATTRIBUTES.COVER_AMOUNT_60_PLUS)?.settings
              )?.items?.map((item) => (
                <div
                  className={
                    selectedOption === item
                      ? style.optionsActive
                      : style.options
                  }
                  onClick={() => {
                    setSelectedOption(item);
                    var arr = [...riskAttributes];
                    const index = riskAttributes?.findIndex(
                      (x) => x.name === ATTRIBUTES.COVER_AMOUNT_60_PLUS
                    );
                    arr[index] = { ...arr[index], value: item };
                    dispatch(updatedAttributes(arr, "QUQ"));
                  }}
                >
                  <div>
                    <img
                      src={
                        selectedOption === item ? radioChecked : radioUnchecked
                      }
                    />
                  </div>
                  {/* {setCoverOption(`P${item} Cover`)} */}
                  <p className="primary-color f-700">P{item} Cover</p>
                </div>
              ))}
            </div>
            {errors?.["Cover Amount 60 Plus"] ? (
              <span className="errors">
                {errors?.["Cover Amount 60 Plus"]?.[0]}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <NavigationButtons
        loading={isLoading}
        isBackDisabled={isLoading}
        backId={"step1_back"}
        nextId={"step1_next"}
        back={handleBack}
        next={handleSubmit}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default Step1;
