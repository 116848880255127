/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Accordion from "../../components/Accordion";
import { travelSuperiorPlan } from "./data-claims";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Claims({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            Claims{" "}
          </div>
        </Row>
        <Row>
          {travelSuperiorPlan?.map((plan, i) => (
            <Accordion key={i} title={plan?.title} style={{ color: "#2f2e80" }}>
              <div style={{ color: "#2f2e80" }}>
                {plan?.answer}
                <ul>
                  {plan?.subAnswer?.map((ans, j) => (
                    <li key={j}>{ans}</li>
                  ))}
                </ul>
                <span style={{ color: "#2f2e80", fontWeight: 900 }}>
                  {plan?.subtitle1}
                </span>
                <ul>
                  {plan?.subAnswer1?.map((ans1, k) => (
                    <li key={k}>{ans1}</li>
                  ))}
                </ul>
                <span style={{ color: "#2f2e80", fontWeight: 900 }}>
                  {plan?.subtitle2}
                </span>
                <ul>
                  {plan?.subAnswer2?.map((ans2, m) => (
                    <li key={m}>{ans2}</li>
                  ))}
                </ul>
                <span style={{ color: "#2f2e80", fontWeight: 900 }}>
                  {plan?.subtitle3}
                </span>
                <ul>
                  {plan?.subAnswer3?.map((ans3, n) => (
                    <li key={n}>{ans3}</li>
                  ))}
                </ul>
                <span style={{ color: "#2f2e80", fontWeight: 900 }}>
                  {plan?.subtitle4}
                </span>
                <ul>
                  {plan?.subAnswer4?.map((ans4, p) => (
                    <li key={p}>{ans4}</li>
                  ))}
                </ul>
              </div>
            </Accordion>
          ))}
        </Row>
        <div style={{ height: "420px" }}></div>
      </Container>
    </div>
  );
}

export default Claims;
