import React from "react";

// <--------- Importing Components from React-bootstrap --------->
import { Container, Nav, Navbar } from "react-bootstrap";

// <--------- Importing Images --------->

// <--------- Importing Essential components --------->
import ButtonWithDownArrow from "../Buttons/ButtonWithArrow";
import Button from "../Buttons/Button";

// <--------- Importing Navbar style sheets ---------->
import Style from "./Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import useWindowDimensions from "../../utils/useWindowDimension";
import { logo, logo1 } from "../../assets/images";
import { clearState } from "../../store/actions/products";

const NavbarComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  return (
    <Navbar bg="rgb(47, 46, 123)" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/*<--------- App Logo Starts ----------> */}

        <Navbar.Brand href="/">
          <img className={Style.logo1} src={logo1} alt="logo" />
        </Navbar.Brand>
        {/* {width < 770 && (
          <ButtonWithDownArrow
            name="Buy Insurance"
            bg={width > 770 ? "black" : "#FBD105"}
            color="white"
            width={width < 330 ? "124px" : ""}
            screenWidth={width}
            fontSize={width < 330 && "14px"}
            padding={width < 330 && "10px 0px 10px 0px"}
          />
        )} */}
        {/*<--------- App Logo Ends ------------> */}

        {/*<--------- Menus Starts -------------> */}

        <Navbar.Collapse className="navbar-expand-md" id="basic-navbar-nav">
          <Nav className="inline-flex justify-content-end w-100">
            <div
              className={classNames(
                width > 770 ? `d-flex` : ``,
                Style.header_navs
              )}
              style={{
                width: width > 770 ? (width < 1025 ? "55%" : "35%") : "100%",
              }}
            >
              <Nav.Link href="/" active={location.pathname === "/"}>
                Home
              </Nav.Link>
              <Nav.Link
                href="/products"
                active={location.pathname === "/products"}
              >
                Products
              </Nav.Link>
              <Nav.Link href="/faq" active={location.pathname === "/faq"}>
                FAQ
              </Nav.Link>

              <Nav.Link
                href="/contact"
                active={location.pathname === "contact"}
              >
                Contact
              </Nav.Link>
              {/* <Nav.Link href="/forms" active={location.pathname === "contact"}>
                Get Insured
              </Nav.Link> */}
            </div>
            {/* <div className={width > 374 ? "d-flex" : ""}>
              {width > 770 && (
                <ButtonWithDownArrow
                  name="Buy Insurance"
                  id="landing-page-buy-insurance"
                  bg="#fbcf2c"
                  color="white"
                  // onClick={() => {
                  //   dispatch(clearState()).then(() => {
                  //     if (location.pathname === "/forms") {
                  //       window.location.reload();
                  //     } else {
                  //       window.location.href = "/forms";
                  //     }
                  //   });
                  // }}
                  onClick={() => {
                    const inputField = document.getElementById("dob-id");
                    if (inputField) {
                      inputField.focus();
                    }
                  }}
                />
              )}
            </div> */}
            <div style={{ marginLeft: "20px" }}>
              <Nav.Link
                href="/claims"
                active={location.pathname === "claims"}
                style={{
                  color: "#2f2e80",
                  border: "2px solid #2f2e80",
                  padding: "6px 16px",
                  textDecoration: "none",
                  borderRadius: "4px",
                  display: "inline-block",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Submit Claim
              </Nav.Link>
            </div>
          </Nav>
        </Navbar.Collapse>

        {/*<--------- Menus Ends ----------> */}
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
