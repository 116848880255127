export const travelSuperiorPlan = [
  {
    title: "What is the waiting period for Claims?",
    subAnswer: [
      "The waiting period starts after the receipt of the first premium, i.e. Date of commencement.",
    ],
    subtitle1: "Mosako under 60 ",
    subAnswer1: [
      "Suicide - 24 months",
      "Non-Accidental Death – 6 months",
      "Accidental death -None",
    ],
    subtitle2: "Mosako over 60",
    subAnswer2: [
      "Suicide - 24 months",
      "Non-Accidental Death – 12 months",
      "Accidental death -None",
    ],
    subtitle3: "Claims Requirements",
    subAnswer3: [
      "A claim needs to be reported within three months from the incident date The following documentation is required:",
    ],
    subtitle4: "Death claim requirements",
    subAnswer4: [
      "Signed application form (only for translated policies where the signed application form is not scanned into the system)",
      "Certified copy of death certificate",
      "Certified copy of the claimant’s Omang (ID) or passport completed claim form",
      "Police report (accidental death claims only)",
      "Copy of stillbirth certificate and the pregnancy summary report (stillborn)",
      "Any other documentation that might be required",
    ],
  },
  {
    title: "Are there any Claim exclusions?",
    answer:
      "No claim will be payable in the event of death being attributable directly or indirectly to:",
    subAnswer: [
      "War, invasion, acts of foreign enemy, hostilities, rebellion, revolution, insurrection or military or usurped power, or by ionising radiations or contamination by radioactivity from any nuclear fuel or waste or",
      "Intentional contravention of any criminal law, whether legislative or at common law, by the insured, or by anyone acting on behalf of the insured or with the insured’s permission or knowledge or by any person claiming any benefit under the policy.",
      "Death caused by driving without a valid driver’s license or driving under the influence of alcohol or any illegal drugs",
    ],
  },
];
