/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  const handleSubmit = async () => {
    try {
      if (!dob) {
        return setErrors({ dob: ["Date of birth is required"] });
      }
      setIsLoading(true);
      setIsCounter(true);
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }

      await dispatch(login());
      if (parseQuery(location.search).token) {
        await dispatch(getAgentOnboarding(parseQuery(location.search).token));
      } else if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductAttachments());
      await dispatch(getProductTypeDetails());
      const asyncForEach = async (arr, callback) => {
        for (let i = 0; i < arr.length; i++) {
          await callback(arr[i]);
        }
      };
      const act = async () => {
        await asyncForEach(riskRes.risks, async (risk) => {
          await dispatch(getProductRisksAttributes(risk.instanceId));
        });
      };
      await act();
      history("/forms");
      setIsLoading(false);
      setIsCounter(false);
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            dob,
          },
        })
      );

      //trackUserEvents
      trackUserEvents("bw_care_insurnace_get_a_quote_cta", {});
    } catch (err) {
      console.log(err);
    }
  };

  const [dob, setDOB] = useState(null);

  const calculateMinDate = (type) => {
    return moment().subtract(18, "years")?.format("YYYY-MM-DD");
  };

  const customAttributes = useSelector(
    (state) => state?.products?.customAttributes
  );

  return (
    <div>
      {width > 1024 ? (
        <>
          <div className="homepage-banner-pattern">
            <Container>
              {/* <div className="homepage-banner-pattern"> */}
              <Row className="py-3 ">
                <Col lg={5} xs={12} className="text-center main__img_container">
                  <img className="main__img" src={FullMain} alt="main" />
                </Col>
                <Col lg={7} xs={12} className="homepage__main__text">
                  <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                    <p style={{ textAlign: "center" }}>Mosako Funeral Plan </p>
                  </Row>
                  {/* <Row className="homepage1__heading2 m-0 pt-1 justify-content-center" style={{ marginLeft: '16px' }}> */}
                  <Row className="homepage1__heading2 m-0 pt-1 justify-content-center">
                    <p style={{ textAlign: "center" }}>
                      The loss of a loved one is a difficult and emotional time
                      for family members left behind
                    </p>
                  </Row>
                  <Row className="py-4 m-0 justify-content-center">
                    {/* <Col xs={6} md={4} className="p-0"> */}
                    <p
                      style={{
                        color: "#FBD105",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Fill in the form and get a quote now!
                    </p>
                    {/* </Col> */}
                  </Row>
                </Col>
              </Row>
              {/* </div> */}
            </Container>
          </div>
          <div
            style={{
              backgroundColor: "#E5E5F5",
            }}
          >
            <Container>
              <Row className="mt-0">
                <Col sm={12}>
                  <Card2 width={width} />
                </Col>
              </Row>
            </Container>
          </div>
          {/* </div> */}
          {/* </Container> */}
        </>
      ) : (
        <div className="homepage-banner-pattern">
          <Row
            className="py-3 justify-content-center"
            style={{ margin: "15px" }}
          >
            <Col lg={7} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Mosako Funeral Plan
              </Row>
              <Row
                className="homepage1__heading2 m-0 pt-1 justify-content-center"
                style={{ marginLeft: "16px" }}
              >
                The loss of a loved one is a difficult and emotional time for
                family members left behind
              </Row>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="text-center main__img_container"
              style={{ marginBottom: "30px" }}
            >
              <img className="main__img" src={FullMain} alt="main" />
            </Col>
            <Row
              className="justify-content-center"
              style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
            >
              <Row
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "15px",
                }}
              >
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  Mosako Family Funeral
                </h4>
              </Row>
              <Row>
                <h6
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Cover Option for under 60 and over 60's
                  <br />
                </h6>
              </Row>
              <Row>
                <div className="white_formbox d-flex justify-content-center flex-column">
                  <div className="text-center text-white mt-2">
                    When were you born?*
                  </div>
                  <div className="d-flex  justify-content-center align-items-center flex-column mt-0 w-100">
                    <input
                      type="text"
                      max={calculateMinDate()}
                      style={{
                        margin: 5,
                        width: "250px",
                        height: "34px",
                        backgroundColor: "white",
                      }}
                      onChange={(e) => {
                        dispatch(
                          updateCustomAttributes({
                            type: "CUSTOM_ATTRIBUTES",
                            payload: {
                              ...customAttributes,
                              dateOfBirth: e.target.value,
                            },
                          })
                        );
                        setDOB(e.target.value);
                      }}
                      placeholder="dd/mm/yyyy"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "date")}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      value={dob}
                      min={"1948-01-01"}
                      className="mt-3"
                    />
                    {errors?.dob ? (
                      <span className="errors" style={{ color: "#fff" }}>
                        {errors?.dob?.[0]}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      id="home_quote_button"
                      onClick={handleSubmit}
                      className="btn btn-warning quote_button"
                      style={{ color: "#2F2E80", fontWeight: "bold" }}
                    >
                      Get a quote
                    </Button>
                  </div>
                </div>
              </Row>
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                }}
              >
                From: P 55 / Monthly
              </Row>
              <Row className="justify-content-center mt-3 text-white">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "8px",
                  }}
                >
                  Each Option Covers:
                </h4>
              </Row>
              <Row className="justify-content-center text-center higlight_text">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    marginBottom: "15px",
                  }}
                >
                  Policyholder, one spouse and maximum of 4 children with a
                  choice to add parents/parents in law or extended family
                  members
                </h4>
              </Row>
              <Row className="justify-content-center mt-3 text-center text-white">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    marginBottom: "25px",
                  }}
                >
                  All valid claims are paid in less than 48 hours after all the
                  required claim documents have been submitted.
                </h4>
              </Row>
              <hr
                style={{
                  color: "white",
                  height: 1,
                  width: "97%",
                  margin: "auto",
                  marginTop: "20px",
                }}
              />
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "8pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding: "14px",
                  fontWeight: "bold",
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "1px",
                  }}
                >
                  Policy underwritten by
                </h6>
              </Row>
              <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={liberty}
                    style={{
                      marginBottom: "20px",
                      width: "95px",
                    }}
                    alt="liberty"
                  />
                </div>
              </Row>
            </Row>
          </Row>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;

const data2 = [
  "There are two cover options, a Basic and a Comprehensive option so you can choose the one most suitable to your needs and financial circumstance.",
  "Each option covers a policyholder, one spouse and a maximum of six children with a choice to add parents and parents in law as well as extended family members.",
  "All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.",
];
