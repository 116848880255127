import { Accordion, Divider } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DateInput, TextInput, TwoButtons } from "../../../components";
import Summary from "../../../components/Forms/Summary";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  issuePolicy,
  addPolicyBankDetails,
  sendOTP,
  validateOTP,
  approvePolicy,
} from "../../../store/actions/products";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";


const Step6 = ({ handleNext, handleBack }) => {
  const [attributes, setAttributes] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );
  const premiumAmount = useSelector((state) => state?.products?.premium);
  const policy = useSelector((state) => state?.products?.policy);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [terms, setTerms] = useState({
    aggreement: null,
    terms: false,
    declaration: false,
  });

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const handleChange = (evt) => {
    setAttributes({
      ...attributes,
      [evt.target.name]: evt.target.value,
    });
  };

  const getAttributes = (value) => {
    return QUQRiskAttributes.find((x) => x.name === value);
  };

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  const otpResponse = useSelector((state) => state?.products?.otpResponse);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (1) {
        const data = {
          type: attributes.type,
          pennyDropStatus: "0",
          pennyDropMessage: null,
          bank: attributes.bank,
          accountNumber: attributes.accountNumber,
          accountHolder: attributes.accountHolder,
          branch: attributes.branch,
          iban: "IB-200",
          swift: "SW-200",
        };

        const res = await dispatch(
          addPolicyBankDetails(
            policy.policySaleReference,
            policy.policies[0].policyReference,
            data
          )
        );

        const approvData = {
          policyReference: policy.policies[0].policyReference,
          comment: "submitted from react front end",
        };
        // approvePolicy
        await dispatch(approvePolicy(approvData));
        //trackUserEvents
        trackUserEvents("bw_application_payment_details_confirm_cta", {});
        setIsLoading(false);
        dispatch(storeState());
        navigate("/payment-success");
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  console.log(errors);

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">
              Application & payment details
            </h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Application & payment details</h2>
        </div>
      )}

      <div className="mt-4 h-100">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
          <Summary
            isSummary={true}
            title="Mosako Funeral Plan"
            referenceNumber={
              policy?.policies && policy?.policies[0]?.policyReference
            }
            policyHolder={mainMember?.firstName ?? "NA"}
            policyType={
              riskAttributes
                ?.find((y) => y.code === RISKS.QUQ)
                ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)
                ?.value
            }
            coverType={
              riskAttributes
                ?.find((y) => y.code === RISKS.QUQ)
                ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)
                ?.value
            }
            policyHolderSurname={mainMember?.lastName ?? "NA"}
            familyPolicy={
              dateOfBirth && calculateAge60(dateOfBirth) < 60
                ? "P15,000"
                : `P${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.QUQ)
                      ?.attributes?.find(
                        (x) => x.name === "Cover Amount 60 Plus"
                      )?.value
                  }`
            }
            coverAmount={
              dateOfBirth && calculateAge60(dateOfBirth) < 60
                ? "P15,000"
                : `P${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.QUQ)
                      ?.attributes?.find(
                        (x) => x.name === "Cover Amount 60 Plus"
                      )?.value
                  }`
            }
            premiumAmount={
              calculatorResponse?.productOptions[0]?.risks[0]?.rating?.total
                ?.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0.00"
            }
            extendedCover={
              riskAttributes
                ?.find((y) => y.code === RISKS.QUQ)
                ?.attributes?.find((x) => x.name === "Extended Family")
                ?.value === "YES"
                ? `P${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.QUQ)
                      ?.attributes?.find(
                        (x) => x.name === "Extended Family Cover Amount"
                      )?.value
                  }`
                : null
            }
            riderBenefit={
              riskAttributes
                ?.find((y) => y.code === RISKS.QUQ)
                ?.attributes?.find((x) => x.name === "Life Rider Benefit")
                ?.value === "YES"
                ? `P${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.QUQ)
                      ?.attributes?.find(
                        (x) => x.name === "Additional Rider Benefit Amount"
                      )?.value
                  }`
                : null
            }
          />
          <Accordion
            defaultExpanded={true}
            style={{ maxWidth: "460px", width: "100%" }}
          >
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <h6 className="mt-3">Declarations</h6>
                <p>
                  I have read and understood the information for the
                  policyholder & conditions of insurance made available to me/us
                  and agree to accept the insurance to the terms conditions of
                  exclusions and limitations.
                  <br />
                  <br />
                  I confirm that I/am we/are residents of Botswana
                  <br />
                  <br />I consent to provider, providing me with the information
                  for the policyholder & conditions of insurance, the policy
                  overview and the certificate of insurance via email
                </p>
                <TwoButtons
                  isField={true}
                  half={true}
                  state={terms.declaration}
                  errors={errors["declaration"]}
                  onClick={(e) => setTerms({ ...terms, declaration: e })}
                  options={[
                    {
                      name: "Yes",
                      value: 1,
                    },
                  ]}
                />

                <Divider />

               
                <div className=" mt-3 d-flex justify-content-between flex-row align-items-start">
                  <TwoButtons
                    isField={true}
                    half={true}
                    onClick={(e) => setTerms({ ...terms, terms: e })}
                    state={terms.terms}
                    errors={errors?.["terms"]}
                    options={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                    ]}
                  />
                  <p>
                    By ticking this box. I accept that the premium for this
                    policy will be collected monthly for payment. I further
                    declare that I have read and understood the Payment Terms &
                    Conditions.
                  </p>
                </div>
                <Divider />
                <div style={{ height: "20px" }} />
                <button
                  id="confirm_purchase"
                  className={style.confirm_purchase}
                  onClick={() => {
                    if (
                      !terms?.terms ||
                      !terms?.declaration
                    ) {
                      return setErrors({
                        ...errors,
                        declaration: !terms?.declaration
                          ? ["Please review the declaration"]
                          : [],
                        terms: !terms?.terms ? ["Please review the terms"] : [],
                      });
                    } else {
                      setErrors({
                        ...errors,
                        terms: [],
                        declaration: [],
                        aggreement: [],
                      });
                      handleSubmit();
                    }
                  }}
                >
                  {isLoading ? (
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    "Confirm & Submit"
                  )}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium</h5>
          <h5 className="text-white f-700">
            P
            {calculatorResponse?.productOptions[0]?.risks[0]?.rating?.total
              ?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        backId = {"step6_back"}
        nextId = {"step6_next"}
        isBackDisabled={isLoading}
        back={() => {
          if (dateOfBirth && calculateAge60(dateOfBirth) < 60) {
            //trackUserEvents
            trackUserEvents("bw_application_payment_details_back_cta", {});
            handleBack();
          } else {
            //trackUserEvents
            trackUserEvents("bw_application_payment_details_back_cta", {});
            handleBack(3);
          }
        }}
        payment={true}
        next={() => {}}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step6;
