// initial state of the reducer
const initialState = {
  companyDetails: {},
  currentUserToken: null,
  agentOnboarding: {},
  agentCode:
    process.env.REACT_APP_ENV_TYPE === "PROD" ? "letsgobw-1" : "BIHL-1",
  loading: true,
  bank: {},
};
// function to update state
function auth(state = initialState, action) {
  // destructuring the action from function
  const { type, payload } = action;

  // switch to match type and update payload
  switch (type) {
    case "LOGIN":
      return {
        ...state,
        ...payload,
      };

    case "DETAILS":
      return {
        ...state,
        details: payload,
      };

    case "BANK_OPTIONS":
      return {
        ...state,
        bank: payload,
      };

    case "REFERER_DATA":
      return {
        ...state,
        refererData: payload,
      };

    case "AGENT_ONBOARDING":
      return {
        ...state,
        agentOnboarding: payload,
      };

    case "STORE_COMPANY_DETAILS":
      return {
        ...state,
        companyDetails: payload,
        agentCode: action.agentCode,
      };

    case "LOADING":
      return {
        ...state,
        loading: payload,
      };

    case "CURRENT_USER_TOKEN":
      return {
        ...state,
        currentUserToken: payload,
      };

    // return default state if the type doesnt match any case
    default:
      return state;
  }
}

export default auth;
