import { Divider } from "@mui/material";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { botswanapost, kazang, orangeMoney } from "../../assets/images";
import style from "../components.module.css";
import { calculateAge60 } from "../../utils/utils";


const Summary = ({
  title,
  referenceNumber,
  isSummary = false,
  policyHolder,
  coverType,
  policyHolderSurname,
  premiumAmount,
  policyType,
  familyPolicy,
  coverAmount,
  extendedCover,
  riderBenefit,
  isSuccess = false,
}) => {
  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const QUQPrevRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return (isSuccess ? QUQPrevRiskAttributes : QUQRiskAttributes)?.find(
      (x) => x?.name === value
    );
  };

  const dateOfBirth = useSelector(
    (state) => state?.products.customAttribues.dateOfBirth
  );

  return (
    <div className={style.summary_container}>
      <Row className="justify-content-center">
        <Col lg={12}>
          {!isSuccess ? (
            <h3 className="primary-color f-700 text-center">
              {dateOfBirth && calculateAge60(dateOfBirth) < 60
                ? getAttributes("Cover Option")?.value
                : title}
            </h3>
          ) : (
            <h3 className="primary-color f-700 text-center">Hurray!</h3>
          )}
        </Col>
      </Row>

      {!isSummary && (
        <Row className="justify-content-center">
          <Col lg={12}>
            <p className="primary-color f-800 text-center">
              All that's left is payment. <br />
              {/* (Please check you spam folder) */}
            </p>
          </Col>
        </Row>
      )}

      <Row className="justify-content-between mt-4">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Applicant :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">
            {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") <
            60
              ? `${getAttributes("Cover Option")?.value} Cover amount :`
              : `${getAttributes("Cover Option 60 Plus")?.value ==='Individual' ? 'Member only' : 'Member and family'}  policy :`} */}
            Cover amount :
          </p>
        </Col>
        <Col lg={6} xs={6}>
          {/* <p className="primary-color f-700 text-right w-100">{coverType === 0 ? 'Basic' : 'Comprehensive'}</p> */}
          <p className="primary-color f-700 text-right w-100">{familyPolicy}</p>
        </Col>
      </Row>

      {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") >
            60 && <Row className="justify-content-between mt-0">
        <Col lg={6}>
          <p className="primary-color f-700 text-left">Cover Amount :</p>
        </Col>
        <Col lg={6}>
          <p className="primary-color f-700 text-right w-100">{coverAmount}</p>
        </Col>
      </Row>} */}

      {extendedCover && (
        <Row className="justify-content-between mt-0">
          <Col lg={6} xs={6}>
            <p className="primary-color f-700 text-left">
              Extended family cover :
            </p>
          </Col>
          <Col lg={6} xs={6}>
            <p className="primary-color f-700 text-right w-100">
              {extendedCover}
            </p>
          </Col>
        </Row>
      )}

      {dateOfBirth && calculateAge60(dateOfBirth) < 60 && riderBenefit && (
        <Row className="justify-content-between mt-0">
          <Col lg={6} xs={6}>
            <p className="primary-color f-700 text-left">Rider benefit :</p>
          </Col>
          <Col lg={6} xs={6}>
            <p className="primary-color f-700 text-right w-100">
              {riderBenefit}
            </p>
          </Col>
        </Row>
      )}

      <Divider />

      <Row className="justify-content-between mt-4">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Monthly Premium :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            P{premiumAmount}
          </p>
        </Col>
      </Row>

      <Divider />

      {isSuccess && (
        <Row className="justify-content-start mt-3">
          <Col lg={12}>
            <p className="primary-color f-700 text-left m-0 w-100">
              Please use any of the following payment service providers to
              submit your premium payment to complete this application.
            </p>
            <p className="primary-color f-700 text-left m-0 w-100">
              Pay: BOTSWANA LIFE
            </p>
            <p className="primary-color f-700 text-left m-0 w-100">
              Reference number: {referenceNumber}
            </p>
          </Col>
        </Row>
      )}

      {isSuccess && (
        <Row className="justify-content-start mt-3">
          <Col lg={12}>
            <div className="d-flex justify-content-center align-items-center flex-row">
              <img src={orangeMoney} />
              <img src={kazang} style={{ marginLeft: "10px" }} />
            </div>
            {/* <div className="d-flex justify-content-center align-items-center flex-row">
            <img src={botswanapost} />
           
          </div> */}
          </Col>
        </Row>
      )}

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            Support contact details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* • lclientservices@blil.co.bw */}
              lclientservices@blil.co.bw
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* • +267 36 23 700 */}
              +267 36 23 700
            </li>
          </ul>
        </Col>
      </Row>

      {!isSummary && (
        <>
          <Divider />
          <Row className="justify-content-start mt-3">
            <p className="primary-color f-700 text-center">
              Use the navigation bar on the left to return to the home page
            </p>
          </Row>
        </>
      )}
    </div>
  );
};

export default Summary;
