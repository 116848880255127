import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import { liberty } from "../../../assets/images/index";
import { calculateAge60 } from "../../../utils/utils";

const PaymentSuccess = () => {
  const { width } = useWindowDimensions();
  // document.body.style = "background: #3C409610;";
  document.body.style = "background: #E5E5F5;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes?.find((x) => x?.name === value);
  };

  const mainMember = useSelector((state) => state?.prevState?.mainMember);
  const calculatorResponse = useSelector(
    (state) => state?.prevState?.executeCalculator
  );

  const dateOfBirth = useSelector(
    (state) => state?.prevState?.customAttribues?.dateOfBirth
  );

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
                  <h5 className="primary-color f-700"> Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                title="Mosako Funeral Plan"
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={mainMember?.firstName}
                policyHolderSurname={mainMember?.lastName}
                policyType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.LIVES_COVERED
                    )?.value
                }
                coverType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_OPTIONS
                    )?.value
                }
                isSuccess={true}
                premiumAmount={
                  calculatorResponse?.productOptions[0]?.risks[0]?.rating?.total
                    ?.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "0.00"
                }
                familyPolicy={
                  dateOfBirth && calculateAge60(dateOfBirth) < 60
                    ? "P15,000"
                    : `P${
                        riskAttributes
                          ?.find((y) => y.code === RISKS.QUQ)
                          ?.attributes?.find(
                            (x) => x.name === "Cover Amount 60 Plus"
                          )?.value
                      }`
                }
                coverAmount={
                  dateOfBirth && calculateAge60(dateOfBirth) < 60
                    ? "P15,000"
                    : `P${
                        riskAttributes
                          ?.find((y) => y.code === RISKS.QUQ)
                          ?.attributes?.find(
                            (x) => x.name === "Cover Amount 60 Plus"
                          )?.value
                      }`
                }
                extendedCover={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.QUQ)
                    ?.attributes?.find((x) => x.name === "Extended Family")
                    ?.value === "YES"
                    ? `P${
                        riskAttributes
                          ?.find((y) => y.code === RISKS.QUQ)
                          ?.attributes?.find(
                            (x) => x.name === "Extended Family Cover Amount"
                          )?.value
                      }`
                    : null
                }
                riderBenefit={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.QUQ)
                    ?.attributes?.find((x) => x.name === "Life Rider Benefit")
                    ?.value === "YES"
                    ? `P${
                        riskAttributes
                          ?.find((y) => y.code === RISKS.QUQ)
                          ?.attributes?.find(
                            (x) => x.name === "Additional Rider Benefit Amount"
                          )?.value
                      }`
                    : null
                }
              />
              <div
                style={{ maxWidth: "460px", width: "100%" }}
                className="d-flex justify-content-center flex-column"
              >
                <img
                  src={liberty}
                  style={{
                    width: "95px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p style={{ fontSize: 13, textAlign: "center", marginTop: 5 }}>
                  Underwritten by Botswana Life Insurance Company Limited (Reg.
                  No. BW00000719883), a Licensed Life Insurer and an authorised
                  Financial Services Provider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
