// default imports for react project
import React from "react";

// default import for routing in react
import { Link } from "react-router-dom";

// import default styles from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// import classname to combine module css and normal css
import classNames from "classnames";

// Import icon and images from assets folder

// import custom component
import { Href } from "../Heading/index";

// import stylesheet
import Style from "./Navbar.module.css";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { useSelector } from "react-redux";
import { RedirectToExternal } from "../Heading/index";
import { logoBlack, sanlam, liberty, logo, logo1 } from "../../assets/images";
import { NoBackpackSharp } from "@mui/icons-material";

// React functional component starts here..
function Footer() {
  // Get width from util
  const { width } = useWindowDimensions();

  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  // return jsx to display on screen
  return (
    <div
      className={classNames(
        width > 770 ? Style.footer_container : Style.footer_container_xs
      )}
    >
      {width > 770 ? (
        <Container>
          <Row className="justify-content-center">
            <Col
              lg={2}
              // lg={3}
              xs={12}
              className={classNames(`${width > 770 ? "" : "p-0 pb-4"}`)}
              style={
                width > 770
                  ? { transform: "translateY(-20px)" }
                  : { width: "70%" }
              }
            >
              <Link to="/">
                <img
                  src={logo1}
                  alt="iTOO"
                  width={width > 770 ? "170px" : "162px"}
                  className={classNames(
                    `${width > 770 ? "footer_logo_lg" : "footer_logo mb-2"}`
                  )}
                  style={{ marginTop: "10px" }}
                />
              </Link>
              <div style={{ fontSize: "10px", color: "#0A223B" }}>
                {/* <span
                  className="p-0"
                  style={{ fontSize: "10px", color: "#0A223B" }}
                > */}{" "}
                Underwritten by Botswana Life Limited
                <br />
                (Reg No. 1994/001332/06), <br /> a Licensed Life Insurer and an
                authorised <br /> Financial Services Provider.
              </div>
            </Col>
            {/* <Col lg={2} md={6} xs={6} className={classNames("p-0")}> */}
            <Col
              lg={2}
              md={6}
              xs={6}
              className={classNames(
                `${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`
              )}
            >
              <div
                style={{ fontSize: "15px", color: "#0A223B" }}
                className={classNames(Style.footer_header)}
              >
                Legal Stuff
              </div>
              <div style={{ fontSize: "12px" }}>
                {/* <Href link="/faq" style={{ color: "#0A223B" }}> */}
                <Href link="/faq">FAQ</Href>
                {/* <RedirectToExternal
                href="/disclosure"
              >
                Disclosures
              </RedirectToExternal>
              <br /> */}
                {/* <RedirectToExternal
                  // href="https://itoo.co.za/legal-documents"
                  href=""
                  target="_blank"
                >
                  T&Cs
                </RedirectToExternal>
                <br /> */}
                {/* <RedirectToExternal href="" target="_blank"> */}
                <RedirectToExternal href="/privacy-policy">
                  Privacy Policy
                </RedirectToExternal>
                <br />
                <RedirectToExternal href="/disclaimer">
                  Disclaimer
                </RedirectToExternal>
              </div>
            </Col>
            <Col
              lg={2}
              md={6}
              xs={6}
              className={classNames(
                `${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`
              )}
            >
              <div
                style={{ fontSize: "15px", color: "#0A223B" }}
                className={classNames(Style.footer_header)}
              >
                More links
              </div>
              <div style={{ fontSize: "12px" }}>
                <Href link="/contact">Contact Us</Href>
                <Href link="/about-us">About</Href>
                <div
                  style={{ color: "#0A223B", cursor: "pointer" }}
                  onClick={() => {
                    const inputField = document.getElementById("dob-id");
                    if (inputField) {
                      inputField.focus();
                    }
                  }}
                >
                  Buy Insurance
                </div>
                <Href link="/claims">Submit Claim</Href>
              </div>
            </Col>
            <Col
              lg={3}
              // lg={2}
              md={6}
              xs={12}
              // className={classNames(width < 770 ? "mt-5 p-0" : "")}
              className={classNames(
                `${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`
              )}
              // style={{ transform: width > 770 ? "translateX(10%)" : "0px" }}
            >
              <div
                style={
                  width < 430
                    ? { fontSize: "15px", padding: 0, color: "#0A223B" }
                    : { color: "#0A223B" }
                }
                className={classNames(Style.footer_header)}
              >
                Location
              </div>
              <div style={{ fontSize: "12px", color: "#0A223B" }}>
                {companyDetails?.address ? (
                  companyDetails?.address
                ) : (
                  <>
                    Tower C, Zambezi Towers,
                    <br /> Plot 254352, CBD, <br />
                    Gaborone, Botswana
                  </>
                )}{" "}
                <br />
                {companyDetails?.contactNumber ? (
                  companyDetails.contactNumber
                ) : (
                  <>
                    {" "}
                    <span style={{ fontWeight: "bold" }}>M: </span>+267 3643300
                  </>
                )}
                <br />{" "}
                {companyDetails?.contactEMail ? (
                  companyDetails.contactEMail
                ) : (
                  <>
                    <span style={{ fontWeight: "bold" }}>E: </span>
                    Botswana.Communications@letshego.com
                  </>
                )}
              </div>
            </Col>
            <Col
              lg={2}
              // md={6}
              xs={12}
              // className={classNames(width < 770 ? "mt-5" : "")}
              className={classNames(
                `${width > 770 ? "py-0 px-4" : "p-0 pb-4"}`
              )}
            >
              <Row>
                <div className={classNames("p-0 pt-0 pb-1")}>
                  <img src={liberty} alt="Hollard." width="100px" />
                </div>
                <span
                  className="p-0"
                  style={{ fontSize: "10px", color: "#0A223B" }}
                >
                  {" "}
                  Underwritten by Botswana Life Limited
                  <br />
                  (Reg No. 1994/001332/06), <br /> a Licensed Life Insurer and
                  an authorised <br /> Financial Services Provider.
                </span>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <Row>
            <Col xs={6} className="justify-content-center align-items-center">
              <Link to="/">
                <img
                  src={logo1}
                  alt="iTOO"
                  width={width > 770 ? "170px" : "162px"}
                  className={classNames(
                    `${width > 770 ? "footer_logo_lg" : "footer_logo"}`
                  )}
                />
              </Link>
            </Col>

            <Col
              lg={2}
              xs={6}
              className="justify-content-center align-items-center"
            >
              <Row>
                <div className={classNames("p-0 pt-0 pl-5 pb-1")}>
                  <img src={sanlam} alt="Hollard." width="120px" />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Footer;
