export const travelSuperiorPlan = [
  {
    title: "How long can I keep my funeral cover?",
    subAnswer: [
      "Mosako Funeral  policy is a whole of life product, it will cover you until death provided premiums are paid, except for children’s cover which will terminate at the age of 21 years age last birthday or 25 years age last birthday when in fulltime formal education. The life rider benefit will terminate at the age of 70 years age last birthday.",
    ],
  },
  {
    title: "Will I get anything back if I cancel my policy?",
    subAnswer: [
      "In the event of a cancellation during the cooling-off period, any premiums received during the 3-month cooling-off period will be refunded to the premium payer net of the commission provided no claim has been made on the policy.",
      "If you cancel your policy outside of the cooling-off period, you will not be entitled to a refund on premiums.",
    ],
  },
  {
    title: "Can I decide who receives my benefit?",
    subAnswer: [
      "Only one beneficiary is allowed, however, the beneficiary can be anyone nominated by the policyholder.",
    ],
  },
  {
    title: "Will my Cover benefits and premiums increase?",
    subAnswer: [
      "There is no cover growth or escalation on any of the benefits. Premiums are designed to stay level, which means they won’t increase every year. However, your premiums may change if you make alterations to your policy or if the underlying premium rate is changed by the insurer.",
    ],
  },
  {
    title: "What are the Premium Payment Methods & Frequency?",
    subAnswer: [
      "Premiums are only payable monthly by cash through Orange Money, Kazang or Botswana Life Client Service branches nationwide. Premiums can be paid up to a year as a lump sum in advance.",
    ],
  },
  {
    title: "Will I need to undergo medical examinations to apply?",
    subAnswer: ["No medical underwriting is required."],
  },
];
