import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Info, TabBar, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import {
  CreatePolicy,
  executeCalculator,
  policySaleAttachments,
  updateCustomAttributes,
  updatedAttributes,
  UpdateSteps,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { radioChecked, radioUnchecked } from "../../../assets/images";
import moment from "moment";
import localforage from "localforage";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";


const Step5 = ({ handleNext, handleBack, files, handleFormBack }) => {
  const mainMember = useSelector((state) => state?.products?.mainMember);

  const [inputs, setInputs] = useState({
    ...mainMember,
  });
  const activeStep = useSelector((state) => state.products.activeStep);


  const premiumAmount = useSelector(
    (state) =>
      state?.products?.executeCalculator?.productOptions?.[0]?.risks?.[0]
        ?.rating?.total
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  const bands = useSelector((state) => state?.products?.bands);

  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const attOpts = useSelector(
    (state) => state.products?.policyAttachmentsOptions
  );

  const extendedFamilyState = useSelector(
    (state) => state.products?.extendedAttributes
  );

  const [childAttributes, setChildAttributes] = useState(childState);

  const [extendedFamilyAttributes, setextendedFamilytAttributes] =
    useState(extendedFamilyState);


  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);

  const [isFullLoading, setIsFullLoading] = useState(false);

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );
  
  useEffect(() => {
    var dateOfBirth = customAttributes.dateOfBirth;
    setInputs({
      ...inputs,
      dob: inputs?.dob ?? dateOfBirth
    })

  },[customAttributes])


  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const executeCalculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);
  const reducerState = useSelector((state) => state?.products);
  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: new Date(),
      postalAddress: "",
      gender: "",
      country: "",
      telephoneNumber: "",
      idNumber: "",
    },
  ]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {updateState({})},[])

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "UQQ"));
  };

  const [errors, setErrors] = useState({});
  const types = useSelector((state) => state?.products?.types?.types);
  const [isLoading, setIsLoading] = useState(false);

  var getAttr = function () {
    let attrVal = [];
    Object.entries(inputs)
      .filter((x) => x?.length < 15)
      .forEach(([key, value]) => {
        if (key === "maritalStatus") {
          attrVal.push({ name: key, value: value.value });
        } else {
          attrVal.push({ name: key, value: value });
        }
      });
    return attrVal;
  };

  useEffect(() => {
    if(!files || Object.keys(files).length === 0){
      dispatch(UpdateSteps(activeStep-1));
      handleFormBack(activeStep-1)
      forceUpdate();
      
      return;
    };
  },[])

  const reduxState = useSelector((state) => state);

  const [idCount, setIdCount] = useState(0);
  const getIDNumber = () => {
    return `${inputs?.nationalityId}-${idCount - (idCount - 1)}`;
  };

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const getExtended = () => {
    var arr = [];
    bands?.forEach((x) => {
      getRows(x?.value)?.map((y) => {
        arr.push({
          ...x,
          min: x?.name?.split(" ")?.[2]?.split("-")?.[0],
          max: x?.name?.split(" ")?.[2]?.split("-")?.[1],
        });
      });
    });
    return arr;
  };

  const totalParents = () => {
    var total = riskAttributes?.find((x) => x.name === "Cover Option")?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
      ? 4
      : 0;
  };


   const splittedResponse =
     executeCalculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.message?.split(
       " "
     );

   const getCover = (cover) => {
     var arr = [...splittedResponse];
     var index = arr.findIndex((x) => x.includes(cover));

     return arr[index + 1];
   };

  const getUpdatedAttributes = (count) => {
    return new Promise((resolve, reject) => {
      var arr = [
        {
          externalReference: uuidv4(),
          code: "SPO",
          attributes: [
            ...reducerState.riskAttributes
              .find((x) => x.code === "SPO")
              ?.attributes.map((i) => ({
                ...i,
                value: i.name.toLowerCase().includes("title")
                  ? reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.find((q) =>
                        q.name.toLowerCase().includes("gender")
                      )?.value === "Male"
                    ? "Mr"
                    : "Miss"
                  : i.value,
              })),
          ],
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "SPO"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        },
        ...childAttributes.map((x, i) => ({
          attributes: [
            ...Object.keys(x).map((y) => ({
              name: y,
              value: x[y],
            })),
            {
              name: reducerState.riskAttributes
                .find((x) => x.code === "CHD")
                ?.attributes.find((g) => g.name.toLowerCase().includes("title"))
                ?.name,
              value: x["Child Gender"] === "Male" ? "Mr" : "Miss",
            },
            {
              name: "Child ID Number or Passport no",
              value: getIDNumber(count, "child", i),
            },
            {
              name: "Child Option ID or Passport",
              value: "ID Number",
            },
          ],
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "CHD"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        })),
        ...parentState
          ?.filter((f) => f !== null)
          ?.map((x, i) => ({
            attributes: [
              ...Object?.keys(x || {})
                ?.filter((t) => x?.[t])
                ?.map((y) => ({ name: y, value: x[y] })),
              {
                name: reducerState.riskAttributes
                  .find((x) => x.code === "PAR")
                  ?.attributes.find((g) =>
                    g.name.toLowerCase().includes("title")
                  )?.name,
                value: x?.["Parent Gender"] === "Male" ? "Mr" : "Miss",
              },
              {
                name: "Parent ID Number or Passport no",
                value: getIDNumber(count, "parent", i),
              },
              {
                name: "Parent Option ID or Passport",
                value: "ID Number",
              },
            ],
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "PAR"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),
        // ...getParentAttributes(),
        ...extendedFamilyAttributes.map((x, i) => ({
          attributes: [
            ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
            {
              name: reducerState.riskAttributes
                .find((x) => x.code === "EXF")
                ?.attributes.find((g) => g.name.toLowerCase().includes("title"))
                ?.name,
              value: x["Extended Family Gender"] === "Male" ? "Mr" : "Miss",
            },
            {name:"Extended Family date of Birth",
             value: extendedFamilies?.[i]?.dob
            },
            {
              name: "Extended Family ID Number or Passport no",
              value: getIDNumber(count, "extended", i),
            },
            {
              name: "Extended Family Option ID or Passport",
              value: "ID Number",
            },
          ],
          externalReference: uuidv4(),
          valuations: [],
          productRiskReference: reducerState.riskAttributes.find(
            (x) => x.code === "EXF"
          )?.instanceId,
          price: 0.0,
          discount: 0.0,
          tax: 0.0,
          total: 0.0,
        })),
      ];

      let arr2 = [];
      getRows(arr?.length)?.map((r, i) => {
        var temp = [...arr[i]?.attributes];
        if (arr[i]?.attributes?.[0]?.code === "SPO") {
          temp[6] = { ...temp[6], value: `${inputs?.nationalityId}-${i + 1}` };
          temp[5] = { ...temp[5], value: "ID Number" };
          arr2.push({
            ...arr[i],
            attributes: temp,
          });
        } else {
          temp[5] = { ...temp[5], value: `${inputs?.nationalityId}-${i + 1}` };
          arr2.push({
            ...arr[i],
            attributes: temp,
          });
        }
        if (getRows(arr?.length)?.length - 1 === i) {
          resolve(arr2);
        }
      });
    });
  };

  const handleSubmit = async () => {
    if(!files || Object.keys(files).length === 0){
      dispatch(UpdateSteps(activeStep-1));
      handleFormBack(activeStep-1)

      forceUpdate();
      return;
    };
    var constraints = {
      [getAttributes("Life Rider Benefit")?.name]: {
        presence: {
          allowEmpty: false,
        },
      },
    };

    let count =
      parseInt(customAttributes?.numberOfChildren) +
      getExtended()?.length +
      totalParents();

    const getAttries = await getUpdatedAttributes(count);

    if (getAttributes("Life Rider Benefit")?.value === "YES") {
      constraints = {
        ...constraints,
        [getAttributes("Additional Rider Benefit Amount")?.name]: {
          presence: {
            allowEmpty: false,
          },
        },
      };
    }

    const errors = validate(
      {
        [getAttributes("Life Rider Benefit")?.name]:
          getAttributes("Life Rider Benefit")?.value,
        [getAttributes("Additional Rider Benefit Amount")?.name]: getAttributes(
          "Additional Rider Benefit Amount"
        )?.value,
      },
      constraints
    );

    if (errors || errors !== undefined) {
      return setErrors(errors);
    }
    var policyRisks = [
      {
        externalReference: uuidv4(),
        attributes: [
          ...reducerState.riskAttributes
            .find((x) => x.code === "QUQ")
            ?.attributes.map((y) => ({
              dataType: y.dataType,
              name: y.name,
              instanceId: y.instanceId,
              value:
              y.name === "Date of Birth"
                      ? customAttributes?.dob ?? 0
                : y.name === "Number of Children"
                  ? customAttributes?.numberOfChildren ?? 0
                  : y.name === "Extended Family Aged 1-15 Years"
                  ? bands?.find((x) => x.name === "Aged from 1-15")?.value
                  : y.name === "Extended Family Aged 16-25 Years"
                  ? bands?.find((x) => x.name === "Aged from 16-25")?.value
                  : y.name === "Extended Family Aged 26-35 Years"
                  ? bands?.find((x) => x.name === "Aged from 26-35")?.value
                  : y.name === "Extended Family Aged 36-45 Years"
                  ? bands?.find((x) => x.name === "Aged from 36-45")?.value
                  : y.name === "Extended Family Aged 46-55 Years"
                  ? bands?.find((x) => x.name === "Aged from 46-55")?.value
                  : y.name === "Extended Family Aged 56-65 Years"
                  ? bands?.find((x) => x.name === "Aged from 56-65")?.value
                  : y.name === "Extended Family Aged 66-75 Years"
                  ? bands?.find((x) => x.name === "Aged from 66-75")?.value
                  : y.name === "Extended Family Aged 76-85 Years"
                  ? bands?.find((x) => x.name === "Aged from 76-85")?.value
                  : y.value ?? null,
            })),
        ],
        valuations:
          customAttributes?.spouse_cover === 0
            ? executeCalculatorResponse?.productOptions?.[0]?.valuations?.values
            : executeCalculatorResponse?.productOptions?.[0]?.valuations?.values?.filter(
                (x) => x.name !== "Spouse"
              ),
        productRiskReference: reducerState.riskAttributes.find(
          (x) => x.code === "QUQ"
        )?.instanceId,
        price: monthlyPremium ?? premiumAmount,
        discount: 0,

        tax: 0,
        total: monthlyPremium ?? premiumAmount,
      },
      ...getAttries,
      {
        attributes: getAttr(),
        externalReference: uuidv4(),
        valuations: [],
        productRiskReference: reducerState.riskAttributes.find(
          (x) => x.code === "MLI"
        )?.instanceId,
        price: 0.0,
        discount: 0.0,
        tax: 0.0,
        total: 0.0,
      },
    ];
    if (customAttributes?.spouse_cover === 1) {
      policyRisks.splice(1, 1);
    }
    try {
      setIsLoading(true);
      const resCal = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Date of Birth"
                          ? customAttributes?.dob ?? 0
                          : y.name === "Number of Children"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.name === "Extended Family Aged 1-15 Years"
                          ? bands?.find((x) => x.name === "Aged from 1-15")
                              ?.value
                          : y.name === "Extended Family Aged 16-25 Years"
                          ? bands?.find((x) => x.name === "Aged from 16-25")
                              ?.value
                          : y.name === "Extended Family Aged 26-35 Years"
                          ? bands?.find((x) => x.name === "Aged from 26-35")
                              ?.value
                          : y.name === "Extended Family Aged 36-45 Years"
                          ? bands?.find((x) => x.name === "Aged from 36-45")
                              ?.value
                          : y.name === "Extended Family Aged 46-55 Years"
                          ? bands?.find((x) => x.name === "Aged from 46-55")
                              ?.value
                          : y.name === "Extended Family Aged 56-65 Years"
                          ? bands?.find((x) => x.name === "Aged from 56-65")
                              ?.value
                          : y.name === "Extended Family Aged 66-75 Years"
                          ? bands?.find((x) => x.name === "Aged from 66-75")
                              ?.value
                          : y.name === "Extended Family Aged 76-85 Years"
                          ? bands?.find((x) => x.name === "Aged from 76-85")
                              ?.value
                          : y.value ?? null,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
        })
      );
      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: null,
              endDate: null,
              PolicyStatus: "Quoted",
              currencyCode: "BWP",
              members: [
                {
                  firstName: inputs?.firstName,
                  lastName: inputs?.lastName,
                  birthDate: inputs?.dob,
                  title: inputs?.mainGender === "Male" ? "Mr" : "Miss",
                  mobilePhone: inputs?.mobilePhone,
                  mobilePhoneCode:
                    mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
                  nationalityId: inputs?.nationalityId,
                  nationality: inputs?.nationality,
                  incomeTax: inputs?.incomeTax,
                  marriageStatus: inputs?.maritalStatus?.value,
                  gender: inputs?.mainGender,
                  telePhone: inputs?.telePhone,
                  address: inputs?.physicalAddress,
                  address2: inputs?.address2,
                  city: inputs?.city,
                  province: inputs?.region,
                  postalAddress: inputs?.postalAddress,
                  postalCity: inputs?.postalCity,
                  postalProvince: inputs?.postalProvince,
                  workGPSCoordinate: inputs?.occupation,
                  landlinePhone: inputs?.landlinePhone,
                  landlinePhoneCode: `+${inputs?.landlinePhoneCode?.replace(
                    "+",
                    ""
                  )}`,

                  eMail: inputs?.eMail?.length
                    ? inputs?.eMail
                    : "clientservices@blil.co.bw",
                  memberType: 1,
                  relationship: 0,
                },
              ],

              beneficiaries: beneficiaries.reduce((acc, x) => {
                acc = [
                  ...acc,
                  {
                    beneficiaryType: 0,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    country: x.country,
                    dob: x.dob,
                    birthDate: x.dob,
                    title: x.gender === "Male" ? "Mr" : "Miss",
                    nationalityId: x.nationalityId,
                    eMail: x.email?.length
                      ? x.email
                      : "clientservices@blil.co.bw",
                    landlinePhone: x.telephoneNumber,
                    landlinePhoneCode: `+${x?.[
                      "Telephone Number Code"
                    ]?.replace("+", "")}`,
                    postalAddress: inputs?.postalAddress,
                    gender: x.gender,
                    address: inputs?.postalAddress,
                    address1: inputs?.city,
                    address2: inputs?.region,
                    mobilePhone: x.number,
                    mobilePhoneCode: `+${x?.["Phone Number Code"]?.replace(
                      "+",
                      ""
                    )}`,
                    split: x.percentage / 100,
                  },
                ];
                if (x.trusteeName) {
                  acc = [
                    ...acc,
                    {
                      beneficiaryType: 2,
                      firstName: x.trusteeName,
                      lastName: x.trusteeLastName,
                      dob: x.trusteeDob,
                      birthDate: x.trusteeDob,
                      mobilePhone: x.trusteeNumber,
                      mobilePhoneCode: `+${x?.[
                        "Trustee Phone Number Code"
                      ]?.replace("+", "")}`,
                    },
                  ];
                }
                return acc;
              }, []),
              attributes: [],
              risks: policyRisks,
              bankAccounts: null,
              productOptionReference:
                reducerState?.executeCalculator?.productOptions?.[0]
                  ?.productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "856c0883-2794-b487-fed5-d14f92bfa022"
              : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? "e83501db-1c35-278e-8c51-e276d7ac99b8"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );


      let body = {
        externalReference: "CREATE_SALE_300",
        policySaleReference: res?.data?.data?.policySaleReference,
        policyAttachments: [
          {
            externalReference: "OMANG",
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            attachments: [
              {
                instanceId: "100",
                fileName: files?.omang_card_front?.name?.split(".")[0],
                fileExtension: files?.omang_card_front?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "OFID"
                ).instanceId,
              },
              {
                instanceId: "101",
                fileName: files?.omang_card_back?.name?.split(".")[0],
                fileExtension: files?.omang_card_back?.name?.split(".")[1],
                productAttachmentOptionInstanceId: attOpts.find(
                  (opt) => opt.code === "OBID"
                ).instanceId,
              },
            ],
          },
        ],
      };
      let file = [files?.omang_card_front, files?.omang_card_back];
      let tags = Array(2)
        .fill()
        .map((_, i) => (100 + i).toString());
      await dispatch(policySaleAttachments({ body, file, tags }));

      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("bw_life_rider_benefit_option_next_cta", {
        riderBenefit: getAttributes("Life Rider Benefit")?.value === "YES" ? "YES" : "NO",
        coverAmount: getAttributes("Additional Rider Benefit Amount")?.value !== null ? `P${getAttributes("Additional Rider Benefit Amount")?.value} Cover for ${getCover(`P${getAttributes("Additional Rider Benefit Amount")?.value}`)} per month` : 0,
      });
      handleNext();
    } catch (err) {
      setIsLoading(false);
    }
  };


  
  const handleBackTrack = () => {
    //trackUserEvents
    trackUserEvents("bw_life_rider_benefit_option_back_cta", {});
    handleBack();
  };

  const dateOfBirth = useSelector( (state) => state?.products.customAttribues.dateOfBirth);

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (getAttributes("Additional Rider Benefit Amount")?.value) {
      setSelectedOption(
        getAttributes("Additional Rider Benefit Amount")?.value
      );
    }
  }, []);

  const getParsed = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

 

  const monthlyPremium = useSelector(
    (state) => state?.products?.monthlyPremium
  );

  const exeCal = async () => {
    try{
      setIsFullLoading(true);
      const resCal = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.QUQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Number of Children"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.name === "Extended Family Aged 1-15 Years"
                          ? bands?.find((x) => x.name === "Aged from 1-15")
                              ?.value
                          : y.name === "Extended Family Aged 16-25 Years"
                          ? bands?.find((x) => x.name === "Aged from 16-25")
                              ?.value
                          : y.name === "Extended Family Aged 26-35 Years"
                          ? bands?.find((x) => x.name === "Aged from 26-35")
                              ?.value
                          : y.name === "Extended Family Aged 36-45 Years"
                          ? bands?.find((x) => x.name === "Aged from 36-45")
                              ?.value
                          : y.name === "Extended Family Aged 46-55 Years"
                          ? bands?.find((x) => x.name === "Aged from 46-55")
                              ?.value
                          : y.name === "Extended Family Aged 56-65 Years"
                          ? bands?.find((x) => x.name === "Aged from 56-65")
                              ?.value
                          : y.name === "Extended Family Aged 66-75 Years"
                          ? bands?.find((x) => x.name === "Aged from 66-75")
                              ?.value
                          : y.name === "Extended Family Aged 76-85 Years"
                          ? bands?.find((x) => x.name === "Aged from 76-85")
                              ?.value
                          : y.name === 'Life Rider Benefit'
                          ? 'NO'
                          : y.name === 'Additional Rider Benefit Amount'
                          ? null
                          : y.value ?? null,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.QUQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.QUQ)?.code,
              },
            ],
          },
        })
      );
      setIsFullLoading(false);
    }
    catch(err){
      setIsFullLoading(false);
    }
  }

  return state?.length ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Mosako Funeral Plan</h3>
            <h2 className="primary-color f-800">Life Rider Benefit Option</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Mosako Funeral Plan</p>
          <h2 className="primary-color f-800">Life Rider Benefit Option</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-3">
        <Info>
          A life rider benefit can be used as a way to provide inheritance from
          the main life assured to their dependents in the unfortunate event of
          the main life insured’s premature death. This rider will only pay out
          upon the death of the main life AND IT DOES NOT COVER DISABILITY
          CLAIMS. The Life rider can be taken at any age before the 60th
          birthday.
        </Info>
        <div style={{ height: "10px" }} />
        <TwoButtons
          title={"Add a Rider Benefit?"}
          half={true}
          options={[
            {
              name: "Yes",
              value: "YES",
            },
            {
              name: "No",
              value: "NO",
            },
          ]}
          errors={errors?.["Life Rider Benefit"]}
          state={getAttributes("Life Rider Benefit")?.value}
          onClick={(item) => {
            if (item === "NO") {
              dispatch(
                updateCustomAttributes({
                  type: "MONTHLY_PREMIUM",
                  payload: null,
                })
              );
              var arr = [...riskAttributes];
              const index = riskAttributes?.findIndex(
                (x) => x.name === "Life Rider Benefit"
              );
              const index1 = riskAttributes?.findIndex(
                (x) => x.name === "Additional Rider Benefit Amount"
              );
              arr[index] = { ...arr[index], value: item };
              arr[index1] = { ...arr[index1], value: null };
              setSelectedOption(null);
              dispatch(updatedAttributes(arr, "QUQ"));
              return exeCal();
            }
            var arr = [...riskAttributes];
            const index = riskAttributes?.findIndex(
              (x) => x.name === "Life Rider Benefit"
            );
            arr[index] = { ...arr[index], value: item };
            dispatch(updatedAttributes(arr, "QUQ"));
          }}
        />
        <div style={{ height: 10 }} />
        {getAttributes("Life Rider Benefit")?.value === "YES" && (
          <div style={{ maxWidth: "460px", width: "100%" }}>
            <div className={style.coverOptions}>
              <p className="primary-color f-700">
                Choose your cover amount option?
              </p>
              {JSON.parse(
                getAttributes("Additional Rider Benefit Amount")?.settings
              )?.items?.map((item) => (
                <div
                  className={
                    selectedOption === item
                      ? style.optionsActive
                      : style.options
                  }
                  onClick={() => {
                    setSelectedOption(item);
                    dispatch(
                      updateCustomAttributes({
                        type: "MONTHLY_PREMIUM",
                        payload:
                          parseFloat(
                            executeCalculatorResponse?.productOptions?.[0]
                              ?.risks?.[0]?.rating?.total
                          ) + parseFloat(getCover(`P${item}`)),
                      })
                    );
                    var arr = [...riskAttributes];
                    const index = riskAttributes?.findIndex(
                      (x) => x.name === "Additional Rider Benefit Amount"
                    );
                    arr[index] = { ...arr[index], value: item };
                    dispatch(updatedAttributes(arr, "QUQ"));
                  }}
                >
                  <div>
                    <img
                      src={
                        selectedOption === item ? radioChecked : radioUnchecked
                      }
                    />
                  </div>
                  <p className="primary-color f-700">
                    P{item} Cover for {getCover(`P${item}`)} per month
                  </p>
                </div>
              ))}
            </div>
            {errors?.["Additional Rider Benefit Amount"] ? (
              <span className="errors">
                {errors?.["Additional Rider Benefit Amount"]?.[0]}
              </span>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">
            P
            {premiumAmount
              ?.toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      {isFullLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      )}

      <NavigationButtons
        isBackDisabled={isLoading}
        backId={"step5_back"}
        nextId={"step5_next"}
        back={handleBackTrack}
        loading={isLoading}
        next={handleSubmit}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default Step5;
