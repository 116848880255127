// import logo from "./logo.svg";
// import "./App.css";
// import { Route, useLocation, useNavigate } from "react-router-dom";
// import { Routes } from "react-router";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Form from "./screens/Forms/Forms";
// import Homepage from "./screens/Homepage/Home";
// import "./components/common.css";
// import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
// import { useEffect, useState } from "react";
// import useWindowDimensions from "./utils/useWindowDimension";
// import { useDispatch, useSelector } from "react-redux";
// import { getAgentDetails, login, updateLoading } from "./store/actions/auth";
// import { clearRiskState, clearState } from "./store/actions/products";
// import Loader from "./components/Loader";
// import { parseQuery, setParam } from "./utils/utils";

// console.log = () => {};
// console.error = () => {};
// console.warn = () => {};

// function App() {
//   const { width } = useWindowDimensions();

//   const dispatch = useDispatch();
//   const history = useNavigate();
//   const currentToken = useSelector((state) => state.auth.currentUserToken);
//   const companyDetails = useSelector((state) => state.auth.companyDetails);
//   const location = useLocation();
//   const riskAttributes = useSelector((state) => state.products.riskAttributes);

//   const [ishomeLoading, setIsHomeLoading] = useState(true);
//   const agentCode = useSelector((state) => state.auth.agentCode);

//   useEffect(() => {
//     dispatch({
//       type: "CURRENT_USER_TOKEN",
//       payload: parseQuery(location.search).token ?? currentToken,
//     });
//   }, []);

//   useEffect(() => {
//     setParam(history, currentToken);
//   }, [currentToken]);

//   useEffect(() => {
//     if (location.pathname !== "/forms") {
//       dispatch(clearRiskState());
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [location]);

//   // Loader state form
//   const isLoading = useSelector((state) => state.auth.loading);

//   return (
//     <Routes>
//       <Route path="/forms" element={<Form />} />
//       <Route path="/" element={<Homepage isHomeLoading={ishomeLoading} />} />
//       <Route path="/payment-success" element={<PaymentSuccess />} />
//     </Routes>
//   );
// }

// export default App;

/////////adding header & footer///////////////
import logo from "./logo.svg";
import "./App.css";
// import {BrowserRouter, Route, Switch, useLocation, useNavigate,} from "react-router-dom";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { useLocation, useNavigate,} from "react-router-dom";
// import { Routes } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/Navbar";
import TopNavbar from "./components/Navbar/TopNavbar";
// import TopNavbar from "./components/Topbar/Topbar";
import Footer from "./components/Navbar/Footer";
import Form from "./screens/Forms/Forms";
import Homepage from "./screens/Homepage/Home";
import About from "./screens/About/index";
import Products from "./screens/Products";
import Disclosure from "./screens/Disclosures/disclosure";
import PrivacyPolicy from "./screens/Disclosures/privacyPolicy";
import Claims from "./screens/Claims/claims";
import Faq from "./screens/FAQ/faq";
import Contact from "./screens/Contact/contact";
import "./components/common.css";
import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
import { useEffect, useState } from "react";
import useWindowDimensions from "./utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDetails, login, updateLoading } from "./store/actions/auth";
import { clearRiskState, clearState } from "./store/actions/products";
import Loader from "./components/Loader";
import { parseQuery, setParam } from "./utils/utils";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const location = useLocation();
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  const [ishomeLoading, setIsHomeLoading] = useState(true);
  const agentCode = useSelector((state) => state.auth.agentCode);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  useEffect(() => {
    if (location.pathname !== "/forms") {
      dispatch(clearRiskState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);

  return (
    <>
      {/* <BrowserRouter> */}
      {/* {width > 430 ? <TopNavbar /> : null} */}
      {width > 475 ? <TopNavbar /> : null}

      <Navbar />
      <Routes>
        <Route path="/forms" element={<Form />} />
        {/* <------- About page --------> */}
        <Route path="/about-us" element={<About />} />
        {/* <------- Products page --------> */}
        <Route path="/products" element={<Products />} />
        {/* <------- Disclosure page --------> */}
        <Route path="/disclaimer" element={<Disclosure />} />
        {/* <------- PrivacyPolicy page --------> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <------- Claims page --------> */}
        <Route path="/claims" element={<Claims />} />
        {/* <------- FAQs page --------> */}
        <Route path="/faq" element={<Faq />} />
        {/* <------- Contact page --------> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Homepage isHomeLoading={ishomeLoading} />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
      </Routes>
      <div style={{ height: "3px", backgroundColor: "#cfcfd3" }}></div>
      <Footer />
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
